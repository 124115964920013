import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDarkMode } from "../../redux/actions";
import Header from "../../components/Header";

import {
  Grid,
  Switch,
  FormControlLabel,
  Button,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  contentGrid: {
    margin: "0px 20px"
  }
}));

export default function SettingsPage(props) {
  const dispatch = useDispatch();
  const isDark = useSelector(state => state.app.isDark);

  const handleThemeChange = e => {
    window.localStorage.setItem("useDark", e.target.checked);
    dispatch(updateDarkMode(e.target.checked));
  };
  const classes = useStyles();

  return (
    <Grid className="main-container">
      <Header title="User settings" />
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.contentGrid}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              value="isDark"
              checked={isDark}
              onChange={handleThemeChange}
            />
          }
          label="Dark mode"
        />
        <Button disabled={true}>Clear IndexDB</Button>
        <Typography variant="caption" style={{ marginTop: "48px" }}>
          Version 0.9.3
        </Typography>
      </Grid>
    </Grid>
  );
}
