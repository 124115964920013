import { all, takeEvery } from "redux-saga/effects";
import {
  FETCH_TALLIES,
  FETCH_TALLY,
  MUTATE_ADD_TALLY_PACKAGE,
  MUTATE_CREATE_TALLY
} from "../constants";

import {
  fetchTallies,
  fetchTally,
  addPackageTally,
  createTally
} from "./tally";

export default () =>
  all([
    takeEvery(FETCH_TALLIES, fetchTallies),
    takeEvery(FETCH_TALLY, fetchTally),
    takeEvery(MUTATE_ADD_TALLY_PACKAGE, addPackageTally),
    takeEvery(MUTATE_CREATE_TALLY, createTally)
  ]);
