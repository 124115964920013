import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

import * as moment from "moment";

import Header from "../../components/Header";
import SupervisorSelector from "../../components/SupervisorSelector";
import Scorecard from "../../components/Scorecard";
import CustomDateRange from "../../components/CustomDateRange";

import {
  select,
  scaleLinear,
  extent,
  max,
  scaleTime,
  axisBottom,
  axisLeft,
  line,
  curveMonotoneX
} from "d3";
import { getPackages } from "../../Api";

export default function ActivityDashboard(props) {
  const theme = useTheme();
  const [filters, setFilters] = useState({
    supervisorId: undefined
  });
  const dates = useSelector(state => state.app.dates);
  const [packages, setPackages] = useState([]);
  const [metric, setMetric] = useState("package");

  const dayDiff = dates.endDate && dates.endDate.diff(dates.startDate, "days");

  const grouping =
    dayDiff === 0
      ? "hour" // IF one day range - hourly trend
      : dayDiff < 30
      ? "day" // If less than a month - daily trend
      : dayDiff < 90
      ? "week" // If less than 3 months - weekly trend
      : dayDiff < 730
      ? "month" // If less than 2 years, monthly trend
      : "year"; // If more than 2 years, yearly trend

  const fetchPackageData = async () => {
    const { packages } = await getPackages({
      created_by: filters.supervisorId,
      created_at: [
        dates.startDate.startOf("day").format("x"),
        dates.endDate.endOf("day").format("x")
      ],
      countOnly: 1,
      grouping
    });

    setPackages(packages);
  };

  const redraw = async () => {
    const trendChart = select("#trendline"),
      startX = 40,
      startY = 30;
    trendChart.selectAll("*").remove();

    trendChart.attr("width", "100%").attr("height", "250px");

    let g = trendChart.append("g");

    const width = +trendChart.style("width").slice(0, -2),
      height = +trendChart.style("height").slice(0, -2);

    const xFn = scaleTime()
      .domain(extent(packages, d => moment(d.timeIST)))
      .range([startX, width - startX]);

    const yFn = scaleLinear()
      .domain([
        0,
        max(packages, d => (metric === "package" ? +d.count : +d.assetCount))
      ])
      .range([height - startY, startY]);

    const xAxis = axisBottom(xFn).ticks(5),
      yAxis = axisLeft(yFn).ticks(5);

    const dataPoints = g.append("g").attr("id", "data-points");

    function drawLine(x1, y1, x2, y2) {
      g.append("line")
        .attr("class", "axisPointLine")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
        .attr("stroke", theme.palette.primary.light)
        .style("stroke-width", 1)
        .style("stroke-dasharray", "10,5");
    }
    dataPoints
      .selectAll("g")
      .data(packages)
      .enter()
      .append("circle")
      .attr("cx", d => xFn(moment(d.timeIST)))
      .attr("cy", d => yFn(metric === "package" ? d.count : d.assetCount))
      .attr("r", 5)
      .attr("fill", theme.palette.primary.main)
      .on("mouseover", function(d) {
        const yValue = metric === "package" ? d.count : d.assetCount;
        drawLine(startX, yFn(yValue), xFn(moment(d.timeIST)), yFn(yValue));
        drawLine(
          xFn(moment(d.timeIST)),
          height - startY,
          xFn(moment(d.timeIST)),
          yFn(yValue)
        );
        g.append("text")
          .attr("class", "axisPointText")
          .text(yValue)
          .attr("x", startX + 32)
          .attr("y", yFn(yValue) + 3)
          .attr("fill", theme.palette.primary.light);
      })
      .on("mouseout", function(d) {
        g.selectAll(".axisPointLine").remove();
        g.selectAll(".axisPointText").remove();
      });

    const newline = line()
      .x(d => xFn(moment(d.timeIST)))
      .y(d => yFn(metric === "package" ? d.count : d.assetCount))
      .curve(curveMonotoneX);

    trendChart
      .append("path")
      .datum(packages) // 10. Binds data to the line
      .attr("fill", "none")
      .attr("stroke", theme.palette.primary.main)
      .attr("stroke-width", 3)
      // Assign a class for styling
      .attr("d", newline);

    g.append("g")
      .attr("class", "xAxis axis")
      .attr("transform", `translate(0, ${height - startY})`)
      .call(xAxis);
    g.append("g")
      .attr("class", "axis axis--y")
      .attr("transform", `translate(${startX},0)`)
      .call(yAxis);
  };

  useEffect(() => {
    redraw();
  }, [packages, metric]);

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      fetchPackageData();
      const redraw5minutes = setInterval(() => {
        fetchPackageData();
      }, 300000);
      return () => {
        clearInterval(redraw5minutes);
      };
    }
  }, [filters, dates]);

  const totalPackageCount = packages.reduce((accum, p) => accum + p.count, 0);

  const totalAssetCount = packages.reduce(
    (accum, p) => accum + p.assetCount,
    0
  );

  return (
    <Grid className="main-container">
      <Header title="Activity" />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={1}>
        <Grid item>
          <CustomDateRange />
        </Grid>
        <Grid item>
          <SupervisorSelector
            onChange={e =>
              setFilters({
                ...filters,
                supervisorId: (e && e.map(v => v.value)) || null
              })
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justify="center">
        <Scorecard
          caption="Assets packed"
          value={totalAssetCount || 0}
          onClick={() => setMetric("asset")}
        />
        <Scorecard
          caption="Packages done"
          value={totalPackageCount || 0}
          onClick={() => setMetric("package")}
        />
      </Grid>
      <Grid
        container
        spacing={1}
        justify="center"
        style={{ maxWidth: "400px" }}>
        <Typography variant="body2">Packing rate</Typography>
        <svg id="trendline" />
      </Grid>
    </Grid>
  );
}
