import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDates } from "../../redux/actions";

import { DateRangePicker, SingleDatePicker } from "react-dates";

import DateRangeButtons from "./DateRangeButtons";
import moment from "moment";

import "react-dates/lib/css/_datepicker.css";
import "./index.css";

function CustomDatePicker(props) {
  const dispatch = useDispatch();
  const dates = useSelector(state => state.app.dates);
  const [focusedInput, setFocusedInput] = useState(
    props.type === "single" ? false : null
  );

  return props.type === "single" ? (
    <SingleDatePicker
      date={props.date}
      showClearDate
      placeholder={props.placeholder || "date"}
      onDateChange={date => props.onChange({ date })}
      focused={focusedInput}
      onFocusChange={({ focused }) => setFocusedInput(focused)}
      id="single-picker"
      numberOfMonths={1}
      small
      displayFormat={"DD MMM YYYY"}
    />
  ) : (
    <DateRangePicker
      startDate={dates.startDate}
      startDateId="start-date-picker"
      minimumNights={0}
      endDate={dates.endDate}
      endDateId="end-date-picker"
      isOutsideRange={date => date.isAfter(moment())}
      required
      small
      displayFormat={"DD MMM YYYY"}
      onDatesChange={({ startDate, endDate }) =>
        dispatch(updateDates({ startDate, endDate }))
      }
      focusedInput={focusedInput}
      hideKeyboardShortcutsPanel
      onFocusChange={focused => setFocusedInput(focused)}
      calendarInfoPosition="after"
      renderCalendarInfo={() => (
        <DateRangeButtons
          onChange={({ startDate, endDate }) =>
            dispatch(updateDates({ startDate, endDate }))
          }
        />
      )}
    />
  );
}

export default CustomDatePicker;
