import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { updateOrderId } from "../../redux/actions";

import { getDataForMainDashboard, getDataForLoadingDashboard } from "../../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDatePicker from "../../components/CustomDateRange";

import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

import * as moment from "moment";

import Header from "../../components/Header";

import "./index.css";
import {
  faPlaneDeparture,
  faPlaneArrival,
  faWarehouse,
  faArrowCircleUp,
  faArrowCircleDown
} from "@fortawesome/free-solid-svg-icons";

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    jobContainer: {
      width: "320px",
      padding: "10px",
      cursor: "pointer"
    }
  }));
  const classes = useStyles();
  const dates = useSelector(state => state.app.dates);
  const accessor = useSelector(state => state.app.accessor);
  const [busy, setBusy] = useState({ packing: false, loading: false });
  const [packing, setPacking] = useState([]);
  const [tallies, setTallies] = useState([]);

  const fetchLoadingData = async () => {
    const { tallies } = await getDataForLoadingDashboard(
      moment(dates.startDate)
        .startOf("day")
        .format("x"),
      moment(dates.endDate)
        .endOf("day")
        .format("x")
    );
    setTallies(tallies);
    setBusy({ ...busy, loading: false });
  };

  const fetchPackingData = async () => {
    const { orders } = await getDataForMainDashboard(
      moment(dates.startDate)
        .startOf("day")
        .format("x"),
      moment(dates.endDate)
        .endOf("day")
        .format("x")
    );
    setPacking(orders);
    setBusy({ ...busy, packing: false });
  };

  const fetchData = () => {
    setBusy({ packing: true, loading: true });
    fetchPackingData();
    fetchLoadingData();
  };

  useEffect(() => {
    if (dates.startDate && dates.endDate) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const isOpsUser = accessor.groups.some(group =>
    [
      "Application Administrators",
      "Developer Administrators",
      "Operations Managers",
      "Supervisors"
    ].includes(group)
  );

  console.log(tallies);
  return (
    <Grid container style={{ marginTop: "70px" }}>
      <Header title="Dashboard" />
      <Grid container direction="column" alignItems="center">
        <Grid container direction="column" alignItems="center" justify="center">
          {isOpsUser && <CustomDatePicker />}
        </Grid>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3">Packing Jobs</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              {packing.map(
                ({ order_id, correct, planned, wrong, duplicates }) => (
                  <Grid item key={order_id}>
                    <Paper className={classes.jobContainer}>
                      <Grid
                        container
                        direction="column"
                        justify="space-around"
                        style={{ padding: "10px" }}
                        alignItems="center">
                        <Typography
                          variant="h2"
                          onClick={() => {
                            dispatch(updateOrderId(order_id));
                            props.history.push("/orderView");
                          }}>
                          {order_id}
                        </Typography>
                        <Grid container direction="row" justify="space-around">
                          <Typography variant="h3">
                            {correct} / {planned}
                          </Typography>
                          <svg
                            id={`Packing-${order_id}`}
                            width="150px"
                            height="30px">
                            <rect
                              fill={
                                theme.palette.type === "dark"
                                  ? "#303030"
                                  : "#d3d3d3"
                              }
                              x="0"
                              width="100"
                              y="0"
                              height="30"
                            />
                            <text
                              x="104"
                              y="13"
                              fontSize="10"
                              fill={
                                theme.palette.type === "dark" ? "#FFF" : "#000"
                              }>
                              {+((correct / planned) * 100).toFixed(0)} %
                            </text>
                            <rect
                              fill="#D0EFC0"
                              x={(correct / planned) * 100}
                              width={(duplicates / planned) * 100}
                              y="0"
                              height="15"
                            />
                            <rect
                              fill={theme.palette.success.main}
                              x="0"
                              width={(correct / planned) * 100}
                              y="0"
                              height="15"
                            />
                            <rect
                              fill={theme.palette.error.main}
                              x={100 * (1 - wrong / planned)}
                              width={(wrong / planned) * 100}
                              y="15"
                              height="15"
                            />
                            {wrong > 0 && (
                              <text
                                x="110"
                                y="30"
                                fill={theme.palette.error.main}>
                                {wrong}
                              </text>
                            )}
                          </svg>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h3">Tallies</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              {tallies.map(
                ({
                  id,
                  orderId,
                  stage,
                  site,
                  committedPackages,
                  totalPackages,
                  createdAt,
                  createdBy
                }) => (
                  <Grid item key={id}>
                    <Paper
                      className={classes.jobContainer}
                      onClick={() => {
                        dispatch(updateOrderId(orderId));
                        props.history.push(`/tally/${id}`);
                      }}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        style={{ padding: "10px" }}
                        justify="space-around">
                        <Typography variant="h2">{orderId}</Typography>
                        <FontAwesomeIcon
                          icon={
                            stage === "loading"
                              ? faArrowCircleUp
                              : faArrowCircleDown
                          }
                          color="#a5a5a5"
                          title={stage}
                        />
                        <Typography variant="caption">at</Typography>
                        <FontAwesomeIcon
                          icon={
                            site === "source"
                              ? faPlaneDeparture
                              : site === "destination"
                              ? faPlaneArrival
                              : faWarehouse
                          }
                        />
                      </Grid>
                      <Grid container direction="row" justify="space-around">
                        <Grid item>
                          <Typography
                            variant="h2"
                            color={
                              committedPackages === totalPackages
                                ? "default"
                                : "error"
                            }>
                            {committedPackages} / {totalPackages}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Grid container direction="column" justify="center">
                            <Typography variant="caption">
                              Created: {moment(createdAt).fromNow()}
                            </Typography>
                            <Typography variant="caption">
                              Created by: {createdBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
