export const UPDATE_ORDER_ID = "UPDATE_ORDER_ID";
export const UPDATE_DATES = "UPDATE_DATES";
export const UPDATE_TOAST = "UPDATE_TOAST";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_SOUNDS = "UPDATE_SOUNDS";
export const UPDATE_ONLINE = "UPDATE_ONLINE";
export const UPDATE_SYNC_FLAG = "UPDATE_SYNC_FLAG";
export const UPDATE_DARK_MODE = "UPDATE_DARK_MODE";
export const UPDATE_ACCESSOR = "UPDATE_ACCESSOR";

export const FETCH_TALLIES = "FETCH_TALLIES";
export const FETCH_TALLIES_SUCCESS = "FETCH_TALLIES_SUCCESS";
export const FETCH_TALLIES_LOADING = "FETCH_TALLIES_LOADING";
export const FETCH_TALLIES_ERROR = "FETCH_TALLIES_ERROR";

export const FETCH_TALLY = "FETCH_TALLY";
export const FETCH_TALLY_SUCCESS = "FETCH_TALLY_SUCCESS";
export const FETCH_TALLY_LOADING = "FETCH_TALLY_LOADING";
export const FETCH_TALLY_ERROR = "FETCH_TALLY_ERROR";

export const MUTATE_ADD_TALLY_PACKAGE = "MUTATE_ADD_TALLY_PACKAGE";
export const MUTATE_ADD_TALLY_PACKAGE_SUCCESS =
  "MUTATE_ADD_TALLY_PACKAGE_SUCCESS";
export const MUTATE_ADD_TALLY_PACKAGE_LOADING =
  "MUTATE_ADD_TALLY_PACKAGE_LOADING";
export const MUTATE_ADD_TALLY_PACKAGE_ERROR = "MUTATE_ADD_TALLY_PACKAGE_ERROR";

export const MUTATE_CREATE_TALLY = "MUTATE_CREATE_TALLY";
export const MUTATE_CREATE_TALLY_SUCCESS = "MUTATE_CREATE_TALLY_SUCCESS";
export const MUTATE_CREATE_TALLY_ERROR = "MUTATE_CREATE_TALLY_ERROR";
export const MUTATE_CREATE_TALLY_LOADING = "MUTATE_CREATE_TALLY_LOADING";
