import React from "react";

import { Grid } from "@material-ui/core";

export default function RowGrid(props) {
  return (
    <Grid
      container
      spacing={props.spacing || 3}
      style={props.style || { margin: "10px", width: "100%" }}
      direction="row"
      alignItems={props.alignItems || "center"}
      justify={props.justify || "space-evenly"}>
      {props.children}
    </Grid>
  );
}
