import { getToken, baseUrl } from "./index";

/**
 * Retrieve an asset from its "scan_code"
 * @param {string} scanCode
 */
export const getAssetFromScanCode = async (scanCode, orderId) =>
  fetch(
    `${baseUrl}/assets?scan_code=${scanCode}${
      orderId ? `&orderIdCheck=${orderId}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

/**
 *
 * @param {string} search
 */
export const searchAssetFromOrderId = async (orderId, search) =>
  fetch(`${baseUrl}/assets?order_id=${orderId}&search=${search}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const getAssets = async ({
  orderId,
  packedAt,
  search,
  assetGroupCode,
  pageNumber,
  pageSize,
  orderIdCheck,
  scanCode,
  packedBy
}) =>
  fetch(
    `${baseUrl}/assets?${[
      ...(orderId ? [`order_id=${orderId}`] : []),
      ...(search ? [`search=${search}`] : []),
      ...(assetGroupCode ? [`asset_group_code=${assetGroupCode}`] : []),
      ...(pageNumber ? [`pageNumber=${pageNumber}`] : []),
      ...(orderIdCheck ? [`orderIdCheck=${orderId}`] : []),
      ...(scanCode ? [`scan_code=${scanCode}`] : []),
      ...(pageSize ? [`pageSize=${pageSize}`] : []),
      ...(packedBy ? [`packedBy=${packedBy}`] : []),
      ...(packedAt && typeof packedAt === "object"
        ? packedAt.map(p => `packed_at=${p}`)
        : [])
    ].join("&")}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

export const getPackingStatusFromOrderId = async orderId =>
  fetch(`${baseUrl}/assets/packing?orderId=${orderId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 *
 * @param {Object} param0
 */

export const getAssetsFromGroupCode = async ({ groupCode, orderId }) =>
  fetch(`${baseUrl}/assets?asset_group_code=${groupCode}&order_id=${orderId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const getAssetsFromOrderId = async orderId =>
  fetch(`${baseUrl}/assets?order_id=${orderId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const deleteAssetById = async assetId =>
  fetch(`${baseUrl}/assets/${assetId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    }
  }).then(response => response.json());

export const sendAssetListMail = async orderId =>
  fetch(`${baseUrl}/assets/mail`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ orderId })
  }).then(response => response.json());
