import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";
import {
  Dialog,
  MenuItem,
  Chip,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid
} from "@material-ui/core";

import { addAccount } from "../../Api";

export default function InviteUserPopup(props) {
  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    groups: []
  });

  const dropDownGroups = [
    { group: 3, label: "Operations Manager" },
    { group: 4, label: "Supervisor" },
    { group: 5, label: "Viewer" }
  ];

  const dropDowns = dropDownGroups.map(
    dropDownGroup =>
      !newUser.groups.includes(dropDownGroup.group) && (
        <MenuItem key={dropDownGroup.group} value={dropDownGroup.group}>
          {dropDownGroup.label}
        </MenuItem>
      )
  );

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>Invite a new user</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <TextField
              label="email"
              value={newUser.email}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
            />
          </Grid>
          <Grid item>
            <TextField
              label="name"
              value={newUser.name}
              onChange={e => setNewUser({ ...newUser, name: e.target.value })}
            />
          </Grid>
          <Grid item>
            <TextField
              label="phoneNumber"
              value={newUser.phoneNumber}
              onChange={e =>
                setNewUser({ ...newUser, phoneNumber: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            {newUser.groups.map(group => (
              <Chip
                key={group}
                label={
                  dropDownGroups.find(ddgroup => group === ddgroup.group).label
                }
                onDelete={() => {
                  newUser.groups.splice(newUser.groups.indexOf(group), 1);
                }}
              />
            ))}
            <Select
              value=""
              onChange={e =>
                setNewUser({
                  ...newUser,
                  groups: [...newUser.groups, e.target.value]
                })
              }>
              {dropDowns}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            const { err } = await addAccount(newUser);
            err && dispatch(openErrorToast(err));
            props.onClose();
          }}>
          Invite User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
