import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateOrderId, openErrorToast } from "../../redux/actions";

import { withRouter } from "react-router-dom";

import csv from "csvtojson";
import Header from "../../components/Header";

import {
  Grid,
  Button,
  Input,
  TextField,
  Typography,
  Chip,
  Popover
} from "@material-ui/core";

import { uploadAssetList } from "../../Api";

function UploadAssetsPage(props) {
  const dispatch = useDispatch();
  const orderId = useSelector(state => state.app.orderId);

  const [assetList, setAssetList] = useState(false);
  const [server, setServer] = useState(false);
  const [hasScanCode, setHasScanCode] = useState(true);

  const [popover, setPopover] = useState({
    message: "",
    anchorEl: null
  });

  const treatAssetList = async () => {
    let reader = new FileReader();
    reader.onload = async function(e) {
      const jsonArray = await csv({
        Length: "number",
        Width: "number",
        quantity: "number",
        Qty: "number"
      }).fromString(reader.result);
      setHasScanCode(jsonArray.every(asset => !!asset.scan_code));
    };
    reader.readAsText(assetList);
  };

  useEffect(() => {
    if (assetList) treatAssetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetList]);

  useEffect(() => {
    if (!hasScanCode)
      dispatch(openErrorToast("At least some objects don't have scan code."));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScanCode]);
  const handleFileChange = e => {
    const files = Array.from(e.target.files);
    if (files) {
      if (files[0].name.indexOf(".csv") === -1)
        dispatch(openErrorToast("Please upload a CSV file."));
      else setAssetList(files[0]);
    }
  };

  const handleSubmitAssetList = async () => {
    setServer(true);
    await uploadAssetList({ assetList, orderId });
    setServer(false);
    dispatch(updateOrderId(orderId));
    props.history.push(`/orderView`);
  };

  return (
    <Grid
      container
      className="main-container"
      direction="column"
      justify="center">
      <Header title="Upload assets" />

      <Button
        variant="outlined"
        component="label"
        color="primary"
        style={{ width: "200px", border: "2px solid" }}>
        Choose file
        <Input
          onChange={handleFileChange}
          type="file"
          accept=".csv"
          style={{ display: "none" }}
        />
      </Button>
      <Grid style={{ height: "50px" }}>
        {assetList && (
          <Chip
            label={assetList.name}
            onDelete={() => setAssetList(false)}
            style={{ color: hasScanCode ? "grey" : "red" }}
            onClick={e => {
              setPopover({
                anchorEl: e.currentTarget,
                message: hasScanCode ? "No errors found" : "No Scan code"
              });
            }}
          />
        )}
      </Grid>
      <Popover
        open={Boolean(popover.anchorEl)}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        onClose={() => setPopover({ anchorEl: null })}>
        <Typography variant="body1">{popover.message}</Typography>
      </Popover>
      <TextField
        id="orderId"
        type="number"
        value={orderId}
        label="Enter Order ID"
        style={{ width: "200px" }}
        onChange={e => dispatch(updateOrderId(e.target.value))}
      />
      <Button
        id="upload"
        onClick={handleSubmitAssetList}
        disabled={!orderId || !assetList.name || !hasScanCode || server}
        variant="contained"
        style={{ marginTop: "30px", width: "200px" }}
        color="primary">
        Upload Assets
      </Button>
    </Grid>
  );
}

export default withRouter(UploadAssetsPage);
