import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get Accounts
 * @param {number} clientId
 */

export const getAccounts = async ({ pageSize, pageNumber, search }) =>
  fetch(
    `${baseUrl}/accounts?pageSize=${pageSize}&pageNumber=${pageNumber}${
      search && search.length > 2 ? `&search=${search}` : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

/**
 * Get account details from email
 * @param {string} email
 */

export const getAccount = async email =>
  fetch(`${baseUrl}/accounts?email=${email}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const addAccount = async accountData =>
  await fetch(`${baseUrl}/accounts`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(accountData)
  }).then(response => response.json());

/**
 * Add Group to account
 * @param {number} accountId
 * @param {number} groupId
 */

export const addGroupToAccount = async (accountId, groupId) =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "add" })
  }).then(response => response.json());

/**
 *
 * @param {*} accountId
 */
export const setLatestAccessTime = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "setAccessTime" })
  }).then(response => response.json());
/**
 * Remove Group from account
 * @param {number} accountId
 * @param {number} groupId
 */

export const removeGroupFromAccount = async (accountId, groupId) =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "remove" })
  }).then(response => response.json());

/**
 * Activate account
 * @param {number} accountId
 */

export const activateAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "activate" })
  });

/**
 * Deactivate account
 * @param {number} accountId
 */

export const deactivateAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "deactivate" })
  });
/**
 * Set verified_flag = true
 * @param {number} accountId
 */
export const verifyAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "verify" })
  });

/**
 * Get list of supervisors
 */
export const getSupervisors = async () =>
  fetch(`${baseUrl}/accounts?group_id=4&active=1`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
