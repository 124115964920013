import dexie from "./client";

function getTallies() {
  return dexie.table("order_tallies").toArray();
}

async function getSyncedOrderId() {
  const [tally] = await dexie.table("order_tallies").toArray();
  return tally ? tally.orderId : "";
}

async function getLastOrderPackage() {
  const orderpackage = await dexie
    .table("order_packages")
    .orderBy("id")
    .last();
  return orderpackage;
}

function getTallyById(id) {
  return dexie
    .table("order_tallies")
    .where("id")
    .equals(id)
    .first();
}

function getTalliesByOrderId(orderId) {
  return dexie
    .table("order_tallies")
    .where("orderId")
    .equals(orderId)
    .toArray();
}

function addTallyPackage(tallyPackage, synced = 0) {
  return dexie.table("tally_packages").put({ ...tallyPackage, synced });
}

function addOrderPackage(orderPackage) {
  return dexie.table("order_packages").put(orderPackage);
}

function markTallyPackageSynced(id) {
  return dexie.table("tally_packages").update(id, { synced: 1 });
}

function getTallyPackage({ tallyId, packageSequenceNumber, orderId }) {
  return dexie
    .table("tally_packages")
    .where(`[tallyId+orderId+packageSequenceNumber]`)
    .equals([tallyId, orderId, packageSequenceNumber])
    .first();
}

function getTallyPackagesByTallyId(tallyId) {
  return dexie
    .table("tally_packages")
    .where(`tallyId`)
    .equals(tallyId)
    .toArray();
}

function getOrderPackagesByOrderId(orderId) {
  return dexie
    .table("order_packages")
    .where(`orderId`)
    .equals(orderId)
    .sortBy("packageSequenceNumber");
}

function getOrderPackageByOrderIdAndSequenceNumber(
  orderId,
  packageSequenceNumber
) {
  return dexie
    .table("order_packages")
    .where(`[orderId+packageSequenceNumber]`)
    .equals([orderId, packageSequenceNumber])
    .first();
}

function deleteTallies() {
  return dexie.table("order_tallies").clear();
}

function deletePreviousOrderTallies(orderId) {
  return dexie
    .table("order_tallies")
    .where("orderId")
    .notEqual(orderId)
    .delete();
}

function deleteOrderPackages() {
  return dexie.table("order_packages").clear();
}

function deleteOtherOrderSyncedTallyPackages(orderId) {
  return dexie
    .table("tally_packages")
    .where("synced")
    .equals(1)
    .and(tp => tp.orderId !== orderId)
    .delete();
}

function deleteSyncedTallyPackages(orderId, tallyId) {
  return dexie
    .table("tally_packages")
    .where("orderId")
    .equals(orderId)
    .and(tp => tp.tallyId === tallyId && tp.synced)
    .delete();
}

function getUnsyncedTallyPackages() {
  return dexie
    .table("tally_packages")
    .where("synced")
    .equals(0)
    .toArray();
}

function putTally(tally) {
  return dexie.table("order_tallies").put(tally);
}

function bulkPutTallies(tallies) {
  console.log("bpt");
  return dexie.table("order_tallies").bulkPut(tallies);
}
function bulkPutOrderPackages(orderPackages) {
  console.log("bpop");
  return dexie.table("order_packages").bulkPut(orderPackages);
}
function bulkPutTallyPackages(tallyPackages) {
  return dexie.table("tally_packages").bulkPut(tallyPackages);
}

const api = {
  getTallies,
  addTallyPackage,
  getTallyPackage,
  deleteOrderPackages,
  deleteTallies,
  bulkPutTallies,
  bulkPutOrderPackages,
  putTally,
  getTallyPackagesByTallyId,
  bulkPutTallyPackages,
  deleteSyncedTallyPackages,
  getTalliesByOrderId,
  getTallyById,
  getOrderPackagesByOrderId,
  getUnsyncedTallyPackages,
  markTallyPackageSynced,
  deletePreviousOrderTallies,
  deleteOtherOrderSyncedTallyPackages,
  getSyncedOrderId,
  getOrderPackageByOrderIdAndSequenceNumber,
  getLastOrderPackage,
  addOrderPackage
};

export default api;
