import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateOrderId,
  playSuccess,
  playWarning,
  openWarningToast,
  openErrorToast,
  openSuccessToast
} from "../../redux/actions";

import Header from "../../components/Header";
import RowGrid from "../../components/RowGrid";

import {
  Grid,
  Box,
  TextField,
  Chip,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/styles";
import useDebounceState from "../../customHooks/useDebounceState";

import {
  Search as SearchIcon,
  PhotoCamera as PhotoCameraIcon,
  LocationOn as LocationOnIcon,
  LocationOff as LocationOffIcon,
  Help as HelpIcon
} from "@material-ui/icons";

import {
  getAssetFromScanCode,
  getAssetsFromGroupCode,
  getPackingStatusFromOrderId,
  addAssetsToPackage,
  searchAssetFromOrderId,
  reverseGeocode
} from "../../Api";
import db from "../../offlineDb/client";
import "./index.css";

export default function PackingPage(props) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    filterAssetContainer: {
      margin: "10px"
    },
    fullWidth: {
      width: "100%"
    },
    fullWidthMax250: {
      width: "100%",
      maxWidth: "300px"
    },
    assetContainer: {
      marginTop: "20px",
      minHeight: "50px",
      width: "100%"
    },
    assetChip: {
      color: theme.palette.primary.dark
    },
    assetCount: {
      border: "1px solid black",
      height: "32px",
      borderRadius: "50%",
      width: "32px"
    },
    thumbnail: {
      position: "relative",
      width: "160px",
      float: "left",
      margin: "5px",
      display: "inline-block",
      "&hover": {
        opacity: 0.5
      }
    },
    thumbnailImg: {
      height: "75px"
    },
    orderIdContainer: {
      width: "fit-content",
      margin: "5px 0px"
    }
  }));
  const classes = useStyles();
  const [uniqueCode, setUniqueCode] = useState("");
  const orderId = useSelector(state => state.app.orderId);
  const location = useSelector(state => state.app.location);
  const online = useSelector(state => state.app.online);
  const dispatch = useDispatch();

  const [packingStatus, setPackingStatus] = useState({
    planned: 1,
    correct: 0,
    wrong: 0,
    notPacked: 1
  });

  const updatePackingStatus = async () => {
    if (orderId) {
      const { result } = await getPackingStatusFromOrderId(orderId);
      if (result.length > 0) {
        const [{ planned, correct, wrong, notPacked }] = result;
        setPackingStatus({ planned, correct, wrong, notPacked });
      }
    }
  };
  const [packageGroupCode, setPackageGroupCode] = useState(null);
  const [assetsInThisGroup, setAssetsInThisGroup] = useState([]);
  const [dialog, setDialog] = useState({ open: false });
  const [photos, setPhotos] = useState([]);

  const [packageLabel, setPackageLabel] = useState("");
  const [assets, setAssets] = useState([]);

  const [searchAsset, setSearchAsset] = useState({ open: false });

  const [, setOfflinePackages] = useState([]);

  const getAsyncAssetsOfThisGroup = async () => {
    if (packageGroupCode) {
      const { assets } = await getAssetsFromGroupCode({
        groupCode: packageGroupCode,
        orderId
      });
      setAssetsInThisGroup(assets);
    } else setAssetsInThisGroup([]);
  };

  const asyncUpdateAssetsState = async () => {
    const [toScan, ...restAssets] = assets;
    if (online) {
      const {
        assets: [asset]
      } = await getAssetFromScanCode(toScan, orderId);

      // if asset exists
      if (asset) {
        const {
          assetOrderId,
          groupCode,
          packageId,
          packageOrderId,
          packageSequenceNumber
        } = asset;
        if (assetOrderId !== +orderId) {
          dispatch(playWarning());
          dispatch(
            openWarningToast(
              "You are packing something which belongs to another order"
            )
          );
        }
        // If asset is already added to package
        if (packageId) {
          dispatch(playWarning());
          dispatch(
            openWarningToast(
              `Already added to ${packageOrderId}-${packageSequenceNumber}`
            )
          );
        }

        // If package group code doesn't exist yet
        if (!packageGroupCode && groupCode && groupCode !== "")
          setPackageGroupCode(groupCode);
        // If package group code doesn't match group code
        else if (
          packageGroupCode &&
          groupCode &&
          packageGroupCode !== groupCode
        ) {
          dispatch(playWarning());
          dispatch(
            openErrorToast(`Different Group code on ${toScan} - not allowed`)
          );
          // Since assets promise hasn't returned yet, assets will still be pre-new asset push, not sure if this is a good practise, but it was working.
          setAssets(restAssets);
        } else if (!orderId) {
          dispatch(openWarningToast(`Set order Id for module check`));
        }
      } // if asset doesnt exist
      else {
        dispatch(playWarning());
        dispatch(
          openWarningToast(`Asset ${toScan} is not uploaded on Tallyo.`)
        );
      }
    }
  };
  // If assets changes
  useEffect(() => {
    if (assets.length > 0) asyncUpdateAssetsState();
    else setPackageGroupCode(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  // If package group code changes, refresh asset list
  useEffect(() => {
    getAsyncAssetsOfThisGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageGroupCode]);

  const handleDeletePhoto = index =>
    setPhotos(photos.filter((_, photoIndex) => index !== photoIndex));

  const addCode = async codeToAdd => {
    let toScan = codeToAdd || uniqueCode.toUpperCase().replace(/%O/g, "_");
    // If already scanned
    if (assets.find(code => code === toScan)) {
      dispatch(playWarning());
      dispatch(openWarningToast(`Already scanned ${toScan}`));
      setUniqueCode("");
    } else {
      setAssets([toScan, ...assets]);
      setUniqueCode("");
    }
  };

  const showWhyDisabled = () => {
    dispatch(
      openErrorToast(
        `This is why Button is disabled: 
        ${[
          assets.length === 0 && `No assets Added`,
          !orderId && `Order Id not set`,
          !packageLabel
            ? `Package label not set`
            : !/^\d{1,8}-\d{1,5}/.test(packageLabel)
            ? `Not a proper label format format`
            : +packageLabel.split("-")[0] !== +orderId &&
              `Doesnt belong to this order Id`
        ]
          .filter(a => a)
          .join(",")}`
      )
    );
  };

  useEffect(() => {
    updatePackingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog.open]);

  const handleFileAdd = e => {
    if (e.target.files[0]) {
      let newPhotos = photos.slice();
      newPhotos.push(e.target.files[0]);
      setPhotos(newPhotos);
    }
  };

  const canAddPackage = !(
    assets.length === 0 ||
    !orderId ||
    !packageLabel ||
    !/^\d{1,8}-\d{1,5}/.test(packageLabel) ||
    +packageLabel.split("-")[0] !== +orderId
  );

  const handleAddPackage = async () => {
    if (online) {
      dispatch(playSuccess());
      await addAssetsToPackage({
        assets,
        packageLabel,
        photos,
        location
      });
      if (orderId === "") dispatch(updateOrderId(packageLabel.split("-")[0]));
      dispatch(openSuccessToast("Updated on server."));
      updatePackingStatus();
      setUniqueCode("");
      setAssets([]);
      setPackageGroupCode(null);
      setPackageLabel("");
      setPhotos([]);
    } else {
      dispatch(playSuccess());
      dispatch(openSuccessToast("Saved on local."));
      if (orderId === "") dispatch(updateOrderId(packageLabel.split("-")));
      props.history.push(`${window.location.pathname}?orderId=${orderId}`);
      const packageContents = {
        packageLabel,
        assets,
        photos,
        location
      };
      db.table("packages")
        .add(packageContents)
        .then(id => {
          setUniqueCode("");
          setAssets([]);
          setPackageGroupCode(null);
          setPackageLabel("");
          setPhotos([]);
          db.table("packages")
            .toArray()
            .then(uncommittedPackages =>
              setOfflinePackages(uncommittedPackages)
            );
        });
    }
  };

  const handleDelete = codeIndex =>
    setAssets(assets.filter((_, index) => index !== codeIndex));

  const handleKeyPress = e => {
    if (e.which === 13 && e.target.id === "assetCode" && uniqueCode !== "")
      addCode();
  };

  return (
    <Grid
      container
      className="main-container"
      direction="column"
      justify="center"
      alignItems="center">
      <Header title="Packing" />
      {packingStatus.correct > 0 && (
        <LinearProgress
          variant="determinate"
          color="primary"
          className={classes.fullWidth}
          value={(packingStatus.correct / packingStatus.planned) * 100}
        />
      )}
      {packingStatus.wrong > 0 && (
        <LinearProgress
          variant="determinate"
          color="secondary"
          className={classes.fullWidth}
          value={(packingStatus.wrong / packingStatus.planned) * 100}
        />
      )}

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center">
        <Grid
          item
          className={classes.orderIdContainer}
          onClick={() => setDialog({ open: true })}
          style={{
            color: orderId ? "inherit" : theme.palette.error.main
          }}>
          <Typography variant="caption">OrderId</Typography>
          <Typography variant="body2">{orderId || "No Order Id"}</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-end"
            style={{ width: "fit-content", margin: "5px" }}>
            {location.lat === 0 && location.lon === 0 ? (
              <LocationOffIcon />
            ) : (
              <LocationOnIcon
                onClick={async () => {
                  let currentLocation = await reverseGeocode(location);
                  dispatch(openSuccessToast(`You are at ${currentLocation}`));
                }}
              />
            )}
            <Typography variant="body1">
              {assets.length > 0 &&
                assetsInThisGroup.length > 0 &&
                `${packageGroupCode}: ${
                  assetsInThisGroup.filter(
                    a =>
                      a.packageId !== null || // already packed
                      assets.some(asset => asset === a.scanCode) // in current package
                  ).length
                } / ${assetsInThisGroup.length} `}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={0}
        style={{ marginTop: "50px", width: "100%" }}>
        <TextField
          id="assetCode"
          autoFocus
          variant="outlined"
          label="Scan/Enter assets"
          autoComplete="off"
          className={classes.fullWidth}
          value={uniqueCode}
          onKeyPress={handleKeyPress}
          onChange={e => setUniqueCode(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  orderId
                    ? setSearchAsset({ open: true })
                    : dispatch(openErrorToast("Set an order Id"));
                }}>
                <SearchIcon />
              </IconButton>
            )
          }}
        />
      </Grid>

      <Box className={classes.assetContainer} bgcolor="background.paper">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end">
          <Chip label={assets.length} className={classes.assetChip} />
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          {assets.map((code, index) => (
            <Chip
              key={index}
              style={{ margin: "5px" }}
              label={code}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Grid>
      </Box>

      <RowGrid
        justify="center"
        alignItems="center"
        style={{ marginTop: "50px", width: "100%" }}>
        <TextField
          id="packageLabel"
          error={
            !!packageLabel &&
            orderId &&
            /^\d{1,8}-\d{1,5}/.test(packageLabel) &&
            +packageLabel.split("-")[0] !== +orderId
          }
          variant="outlined"
          autoComplete="off"
          value={packageLabel}
          className={classes.fullWidth}
          label="Scan/Enter package code"
          onChange={e => setPackageLabel(e.target.value)}
        />
      </RowGrid>
      <Grid container direction="row-reverse" style={{ marginTop: "25px" }}>
        <IconButton
          variant="outlined"
          component="label"
          style={{ width: "15%" }}>
          <PhotoCameraIcon style={{ margin: "10px" }} />
          <input
            onChange={handleFileAdd}
            accept="image/*"
            type="file"
            style={{ display: "none" }}
          />
        </IconButton>
        {photos &&
          photos.map((photo, index) => (
            <Box className={classes.thumbnail} key={photo.name}>
              <img
                className={classes.thumbnailImg}
                src={URL.createObjectURL(photo)}
                alt="package"
              />
              <Button
                className="delete"
                style={{ color: "white" }}
                onClick={() => handleDeletePhoto(index)}>
                X
              </Button>
            </Box>
          ))}
      </Grid>
      <Grid container direction="row">
        <Button
          id="addPackage"
          variant="contained"
          disabled={!canAddPackage}
          className={classes.fullWidthMax250}
          color="primary"
          onClick={() => handleAddPackage()}>
          Add Package
        </Button>
        {canAddPackage || (
          <IconButton onClick={showWhyDisabled}>
            <HelpIcon />
          </IconButton>
        )}
      </Grid>

      <Dialog open={dialog.open} onClose={() => setDialog({ open: false })}>
        <DialogTitle>Set Order Id</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="orderId"
            className="fullWidthMax400"
            label="Order Id"
            type="number"
            value={orderId}
            onChange={e => {
              dispatch(updateOrderId(e.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(updateOrderId(orderId));
              setDialog({ open: false });
            }}
            color="primary">
            SET
          </Button>
        </DialogActions>
      </Dialog>
      <SearchAssetDialog
        open={searchAsset.open}
        onClose={result => {
          setSearchAsset({ open: false });
          result && result.scanCode && addCode(result.scanCode);
        }}
      />
    </Grid>
  );
}

function SearchAssetDialog(props) {
  const useStyles = makeStyles(() => ({
    filterAssetContainer: {
      margin: "10px"
    }
  }));
  const classes = useStyles();
  const orderId = useSelector(state => state.app.orderId);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceState({ value: search, delay: 1000 });
  const [filterAssets, setFilterAssets] = useState([]);

  const asyncGetFilteredAssets = async () => {
    const { assets } = await searchAssetFromOrderId(orderId, search);
    setFilterAssets(assets);
  };

  useEffect(() => {
    if (search.length >= 4) asyncGetFilteredAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}>
      <DialogTitle>Search asset within order Id: {orderId}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" justify="center">
          <Grid item>
            <TextField
              label="Asset to search"
              autoFocus
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Grid container className={classes.filterAssetContainer}>
              {filterAssets.map(asset => (
                <Grid item key={asset.id}>
                  <Chip
                    label={asset.scanCode}
                    onClick={() =>
                      props.onClose({
                        assetId: asset.id,
                        scanCode: asset.scanCode
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
