import { getToken, baseUrl } from "./index";

export const deletePackage = async packageId =>
  fetch(`${baseUrl}/packages/${packageId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const getPackages = async ({
  orderId,
  created_by,
  created_at,
  countOnly,
  grouping
}) =>
  fetch(
    `${baseUrl}/packages?${[
      ...(orderId ? [`orderId=${orderId}`] : []),
      ...(created_by ? [`created_by=${created_by}`] : []),
      ...(created_at
        ? [`created_at[]=${created_at[0]}&created_at[]=${created_at[1]}`]
        : []),
      ...(countOnly ? [`countOnly=1`] : []),
      ...(grouping ? [`grouping=${grouping}`] : [])
    ].join(`&`)}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

export const addAssetsToPackage = async data => {
  let body = new FormData();
  data.assets.forEach(asset => body.append("assetScanCodes[]", asset));
  body.append("packageLabel", data.packageLabel);
  body.append("latitude", data.location.lat);
  body.append("longitude", data.location.lon);
  data.photos.forEach(photo => body.append("packagePhotos", photo, photo.name));
  fetch(`${baseUrl}/packages/assets`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`
    },
    body
  }).then(response => response.json());
};

/**
 *
 * @param {number} assetId
 * @param {number} packageId
 */

export const removeAssetFromPackage = async (assetId, packageId) =>
  fetch(`${baseUrl}/packages/${packageId}/assets/${assetId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
