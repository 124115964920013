import {
  FETCH_TALLIES_SUCCESS,
  FETCH_TALLIES_LOADING,
  FETCH_TALLY_SUCCESS,
  FETCH_TALLY_LOADING,
  MUTATE_ADD_TALLY_PACKAGE_LOADING,
  MUTATE_ADD_TALLY_PACKAGE_SUCCESS
} from "../../constants";

const initialState = {
  orderTallies: [],
  orderPackages: [],
  tally: {},
  tallyPackages: [],
  loading: false
};

export default function tallyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TALLIES_SUCCESS:
      const { tallies: orderTallies } = action.data;

      return {
        ...state,
        orderTallies,
        loading: false
      };
    case FETCH_TALLIES_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_TALLY_SUCCESS:
      const { orderPackages, tally, tallyPackages } = action.data;

      return {
        ...state,
        orderPackages,
        tally,
        tallyPackages,
        loading: false
      };
    case FETCH_TALLY_LOADING:
      return {
        ...state,
        loading: true
      };
    case MUTATE_ADD_TALLY_PACKAGE_LOADING:
      return {
        ...state,
        loading: true
      };
    case MUTATE_ADD_TALLY_PACKAGE_SUCCESS: {
      const {
        tallyPackage: {
          orderId,
          packageSequenceNumber,
          id,
          customJson,
          createdAt
        }
      } = action.data;

      const { orderPackages } = { ...state };
      const orderPackageIndex = orderPackages.findIndex(
        p =>
          p.orderId === orderId &&
          p.packageSequenceNumber === packageSequenceNumber
      );

      orderPackageIndex === -1 &&
        orderPackages.push({
          orderId,
          packageSequenceNumber,
          id,
          customJson,
          createdAt
        });

      return {
        ...state,
        tallyPackages: [
          ...state.tallyPackages,
          { id, orderId, packageSequenceNumber }
        ],
        tally: {
          ...state.tally
        },
        orderPackages,
        loading: false
      };
    }
    default:
      return state;
  }
}
