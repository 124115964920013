import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faColumns,
  faUpload,
  faBoxOpen,
  faGripHorizontal,
  faTasks,
  faCog,
  faSort,
  faChartBar
} from "@fortawesome/free-solid-svg-icons";

const pages = [
  {
    path: "admin",
    name: "Admin Page",
    icon: <FontAwesomeIcon icon={faCog} size="lg" />,
    description: "Admin page",
    sidebar: true,
    groups: ["Application Administrators", "Developer Administrators"]
  },
  {
    path: "activity",
    category: "view",
    sidebar: true,
    icon: <FontAwesomeIcon icon={faChartBar} size="lg" />,
    name: "Activity",
    description: "Timelines/summary",
    groups: [
      "Developer Administrators",
      "Viewers",
      "Application Administrators",
      "Operations Managers"
    ]
  },
  {
    path: "dashboard",
    category: "view",
    name: "Dashboard",
    icon: <FontAwesomeIcon icon={faColumns} size="lg" />,
    description: "Dashboard",
    sidebar: true,
    groups: [
      "Operations Managers",
      "Viewers",
      "Application Administrators",
      "Developer Administrators",
      "Application Administrators"
    ]
  },
  {
    path: "tally",
    category: "entry",
    name: "Tally",
    description: "Tally during loading",
    sidebar: false,
    groups: [
      "Operations Managers",
      "Developer Administrators",
      "Supervisors",
      "Application Administrators"
    ]
  },
  {
    path: "uploadAssets",
    icon: <FontAwesomeIcon icon={faUpload} size="lg" />,
    name: "Upload assets",
    description: "Upload asset list from csv",
    sidebar: true,
    category: "entry",
    groups: [
      "Operations Managers",
      "Developer Administrators",
      "Application Administrators"
    ]
  },
  {
    path: "pack",
    category: "entry",
    icon: <FontAwesomeIcon icon={faBoxOpen} size="lg" />,
    name: "Packing",
    sidebar: true,
    description: "Tag assets to package ids",
    groups: [
      "Operations Managers",
      "Developer Administrators",
      "Supervisors",
      "Application Administrators"
    ]
  },
  {
    path: "orderView",
    category: "view",
    icon: <FontAwesomeIcon icon={faGripHorizontal} size="lg" />,
    name: "Asset Status",
    description: "View by status, package, group",
    sidebar: true,
    groups: [
      "Developer Administrators",
      "Supervisors",
      "Operations Managers",
      "Application Administrators"
    ]
  },

  {
    path: "orderTally",
    icon: <FontAwesomeIcon icon={faTasks} size="lg" />,
    category: "entry",
    name: "Tallies",
    description: "Loading and unloading tallies",
    sidebar: true,
    groups: [
      "Operations Managers",
      "Developer Administrators",
      "Supervisors",
      "Application Administrators"
    ]
  },
  {
    path: "sort",
    icon: <FontAwesomeIcon icon={faSort} size="lg" />,
    category: "view",
    name: "Sorting",
    description: "Sorting assets to module",
    sidebar: true,
    groups: [
      "Developer Administrators",
      "Operations Managers",
      "Supervisors",
      "Application Administrators"
    ]
  },
  {
    path: "settings",
    icon: <FontAwesomeIcon icon={faCog} />,
    category: "view",
    name: "Settings",
    description: "User Settings",
    sidebar: true,
    groups: [
      "Developer Administrators",
      "Operations Managers",
      "Supervisors",
      "Application Administrators"
    ]
  },
  // Keep this at the last always
  {
    path: "",
    name: "Home Page",
    sidebar: false,
    // Add all possible roles in this project here
    groups: [
      "Application Administrators",
      "Developer Administrators",
      "Supervisors",
      "Operations Managers"
    ]
  }
];

export default pages;
