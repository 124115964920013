import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { removeAssetFromPackage, deleteAssetById } from "../../Api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

function AssetDialog(props) {
  const { open, asset } = props;
  const accessor = useSelector(state => state.app.accessor);

  const isAdmin =
    accessor &&
    accessor.groups &&
    accessor.groups.find(group =>
      ["Developer Administrators", "Application Administrators"].includes(group)
    );

  const {
    id,
    scanCode = "",
    customJson = {},
    assetOrderId,
    packageLabel,
    isDuplicate
  } = asset;

  const handleDeleteButtonClick = async () => {
    await deleteAssetById(id);
    props.onClose();
  };

  const handleSetNotPacked = async () => {
    removeAssetFromPackage(id, asset.packageId);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={() => props.onClose()}>
      <DialogTitle>{scanCode}</DialogTitle>
      <DialogContent>
        {isDuplicate ? (
          <span>
            Duplicate: Yes
            <br />
          </span>
        ) : (
          ``
        )}
        Packed in: {packageLabel} <br />
        Asset OrderId: {assetOrderId} <br />
        <br />
        <b>Asset Data:</b> <br />
        <br />
        {customJson &&
          Object.entries(customJson).map(([key, value]) => (
            <div key={key} className="asset-data">
              {key} : {value} <br />
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        {isAdmin && (
          <Button
            variant="text"
            onClick={
              asset.packageId === null
                ? handleDeleteButtonClick
                : handleSetNotPacked
            }
            color="secondary">
            {asset.packageId === null ? `DELETE` : `SET NOT PACKED`}
          </Button>
        )}
        <Button
          onClick={() => props.onClose("cancel")}
          color="primary"
          variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssetDialog.propTypes = {
  open: PropTypes.bool,
  dialog: PropTypes.object
};

export default AssetDialog;
