import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";
import { makeStyles } from "@material-ui/styles";
import logo from "./auth_service_google.svg";

import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography
} from "@material-ui/core";

import "./index.css";

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: "100%"
  },
  loginButton: {
    margin: "100px 0px",
    width: "100%",
    maxWidth: "400px",
    height: "50px",
    paddingRight: "12px",
    "&:hover": {
      color: "white"
    }
  }
}));

export default function LoginPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [emailDialog, setEmailDialog] = useState({ open: false, email: "" });
  const loginWIthGoogle = () => {
    var provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
      hd: "pikkol.com"
    });

    auth()
      .signInWithPopup(provider)
      .then(function(result) {})
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (auth().isSignInWithEmailLink(window.location.href)) {
      // Signed in from email link
      setEmailDialog({ ...emailDialog, open: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const handleConfirmEmail = () => {
    auth()
      .signInWithEmailLink(emailDialog.email, window.location.href)
      .then(async result => {})
      .catch(error => {
        if (error.code === "auth/invalid-action-code") {
          dispatch(
            openErrorToast(
              "Code is either expired, or already used by another user."
            )
          );
        }
      });
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.fullWidth}>
      <Typography variant="body1" style={{ marginTop: "200px" }}>
        Login to Tallyo using GSuite
      </Typography>

      <Button
        className={classes.loginButton}
        color="primary"
        variant="contained"
        onClick={() => loginWIthGoogle()}>
        <img src={logo} style={{ margin: "10px" }} alt="Sign in with Google" />
        Login with Google
      </Button>
      <Dialog
        open={emailDialog.open}
        onClose={() => setEmailDialog({ open: false, email: "" })}
        style={{ margin: "20px" }}>
        <DialogTitle>Verify Email address</DialogTitle>
        <DialogContent>
          <TextField
            label="Verify email"
            type="email"
            value={emailDialog.email}
            onChange={e =>
              setEmailDialog({ ...emailDialog, email: e.target.value })
            }
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmEmail}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
