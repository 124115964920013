import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";

import { createTally } from "../../redux/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneDeparture,
  faPlaneArrival,
  faWarehouse,
  faArrowCircleUp,
  faArrowCircleDown
} from "@fortawesome/free-solid-svg-icons";

export default function CreateNewTallyDialog(props) {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(props.stage || "");
  const [site, setSite] = useState(props.site || "");
  const location = useSelector(state => state.app.location);

  const handleClick = async () => {
    const successCallBack = props.onClose;
    const payload = {
      orderId: +props.orderid,
      stage,
      site,
      latitude: location.lat,
      longitude: location.lon
    };
    dispatch(createTally(payload, successCallBack));
  };

  const option = (value, icon, label) => (
    <MenuItem value={value}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        style={{ padding: "5px" }}>
        <Grid item>
          <FontAwesomeIcon icon={icon} size="2x" />
        </Grid>
        <Grid item>
          <Typography variant="body2">{label}</Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );

  return (
    <Dialog {...props}>
      <DialogTitle>Create new Tally sheet</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" justify="center">
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Select Stage</InputLabel>
            <Select value={stage} onChange={e => setStage(e.target.value)}>
              {option("loading", faArrowCircleUp, "Loading")}
              {option("unloading", faArrowCircleDown, "Unloading")}
            </Select>
          </FormControl>
          <FormControl style={{ width: "100%", marginTop: "20px" }}>
            <InputLabel>Select Site</InputLabel>
            <Select value={site} onChange={e => setSite(e.target.value)}>
              {option("source", faPlaneDeparture, "Pickup (From source)")}
              {option("in transit", faWarehouse, "Warehouse")}
              {option("destination", faPlaneArrival, "Drop (Destination)")}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClick} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
