import { UPDATE_SYNC_FLAG } from "../constants";
import { addTallyPackage, updateSyncingFlag } from "../actions";
import OfflineDb from "../../offlineDb";

const syncOfflineTallyPackages = () => next => async action => {
  const { type, payload } = action;
  if (type === UPDATE_SYNC_FLAG && payload.syncing) {
    next(action);

    const unsyncedTallyPackages = await OfflineDb.getUnsyncedTallyPackages();
    unsyncedTallyPackages.forEach(data => next(addTallyPackage(data)));
    next(updateSyncingFlag(false));
    return;
  }
  return next(action);
};

export default syncOfflineTallyPackages;
