import {
  UPDATE_ORDER_ID,
  UPDATE_DATES,
  UPDATE_TOAST,
  UPDATE_LOCATION,
  UPDATE_SOUNDS,
  UPDATE_ONLINE,
  FETCH_TALLIES,
  FETCH_TALLY,
  MUTATE_ADD_TALLY_PACKAGE,
  UPDATE_SYNC_FLAG,
  MUTATE_CREATE_TALLY,
  UPDATE_DARK_MODE,
  UPDATE_ACCESSOR
} from "../constants";

export function fetchTallies(orderId) {
  return {
    type: FETCH_TALLIES,
    data: {
      orderId: +orderId
    }
  };
}

export function addTallyPackage(data) {
  return {
    type: MUTATE_ADD_TALLY_PACKAGE,
    data
  };
}

export function fetchTally(tallyId, errorCallBack = () => {}) {
  return {
    type: FETCH_TALLY,
    data: {
      tallyId: +tallyId
    },
    errorCallBack
  };
}

export function updateOrderId(orderId) {
  return {
    type: UPDATE_ORDER_ID,
    payload: { orderId }
  };
}

export function updateDates(dates) {
  return {
    type: UPDATE_DATES,
    payload: { dates }
  };
}

export function openSuccessToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "success", open: true, message } }
  };
}

export function openErrorToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "error", open: true, message } }
  };
}

export function openWarningToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "warning", open: true, message } }
  };
}

export function closeToast() {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { open: false, message: "" } }
  };
}

export function updateLocation(location) {
  return {
    type: UPDATE_LOCATION,
    payload: { location }
  };
}

export function playSuccess() {
  return {
    type: UPDATE_SOUNDS,
    payload: {
      sounds: { success: "PLAYING", alarm: "STOPPED", warning: "STOPPED" }
    }
  };
}

export function playWarning() {
  return {
    type: UPDATE_SOUNDS,
    payload: {
      sounds: { success: "STOPPED", alarm: "STOPPED", warning: "PLAYING" }
    }
  };
}

export function playAlarm() {
  return {
    type: UPDATE_SOUNDS,
    payload: {
      sounds: { success: "STOPPED", alarm: "PLAYING", warning: "STOPPED" }
    }
  };
}

export function stopPlaying() {
  return {
    type: UPDATE_SOUNDS,
    payload: {
      sounds: { success: "STOPPED", alarm: "STOPPED", warning: "STOPPED" }
    }
  };
}

/**
 * @param {Boolean} online
 */
export function updateOnline(online) {
  return {
    type: UPDATE_ONLINE,
    payload: {
      online
    }
  };
}

export function updateSyncingFlag(syncing) {
  return {
    type: UPDATE_SYNC_FLAG,
    payload: {
      syncing
    }
  };
}

export function createTally(payload, successCallBack = () => {}) {
  return {
    type: MUTATE_CREATE_TALLY,
    payload,
    successCallBack
  };
}

/**
 *
 * @param {boolean} darkMode
 */
export function updateDarkMode(darkMode) {
  return {
    type: UPDATE_DARK_MODE,
    payload: {
      isDark: darkMode
    }
  };
}

/**
 *
 * @param {object} accessor
 */
export function updateAccessor(accessor) {
  return {
    type: UPDATE_ACCESSOR,
    payload: { accessor }
  };
}
