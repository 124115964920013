import jsPDF from "jspdf";

import QRCode from "qrcode";

import * as moment from "moment";

/**
 * Convert a string time into -
 * if today time +(relative time)
 * else date and time
 * @param {string} timeString
 */
export function displayTime(timeString) {
  return moment(timeString).isAfter(moment().startOf("day"))
    ? `${moment(timeString).format("hh:mm A")} (${moment(
        timeString
      ).fromNow()})`
    : `${moment(timeString).format("DD MMM YY hh:mm A")}`;
}

/**
 *
 * @param {{x: number, y: number}} location1
 * @param {{x: number, y: number}} location2
 */
export const distance = (location1, location2) =>
  ((location1.x - location2.x) ** 2 + (location1.y - location2.y) ** 2) ** 0.5;

/**
 * Print stickers
 * @param {{label: string, content: object}[]} stickers
 */
export const printStickers = async stickers => {
  const doc = new jsPDF();

  for (const sticker of stickers) {
    const { packageLabel, assets, packedBy } = sticker;
    for (const i in assets) {
      if (i % 6 === 0) {
        doc.setFontSize(15);
        doc.addPage();
        doc.text(`Assets: `, 10, 35);
        doc.text(`Packed By: ${packedBy}`, 10, 25);
        doc.setFontSize(35);
        doc.text(`Package Label: ${packageLabel}`, 10, 15);
      }
      const scanCode = assets[i].scanCode || assets[i];
      const assetQR = await QRCode.toDataURL(assets[i].scanCode || assets[i]);
      doc.setFontSize(35);
      doc.text(scanCode, 10, 50 + (i % 6) * 40);
      doc.addImage(assetQR, "SVG", 140, 30 + (i % 6) * 40, 40, 40);
    }
  }
  doc.deletePage(1);
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const flattenObject = ob =>
  Object.entries(ob).reduce(
    (acc, [key, value]) =>
      key === null || value === null
        ? acc
        : typeof value === "object"
        ? { ...acc, ...flattenObject(value) }
        : { ...acc, [key]: value },
    {}
  );

export const arrayToCSV = (data, fileName = "abc") => {
  const keys = Object.keys(flattenObject(data[0]));

  const csvBlob =
    keys.join(`,`) +
    `
` +
    data
      .map(row => flattenObject(row))
      .map(row => keys.map(key => `"${row[key]}"` || `""`).join(`,`)).join(`
`);
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvBlob}`);
  var link = document.createElement("a");
  link.href = encodedUri;
  link.download = `${fileName}.csv`;
  link.click();
};
