import React from "react";

export default function ErrorPage(props) {
  return (
    <div style={{ margin: "10px" }}>
      <h1> 404 ERROR</h1>
      <p style={{ margin: "10px" }}>
        Cannot find this page on this server. Please press back button on the
        browser and check the url
      </p>
    </div>
  );
}
