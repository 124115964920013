import React from "react";
import { IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: "#004000"
  },
  error: {
    backgroundColor: "#bf0000"
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: "#a87e00"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  }
}));

function CustomSnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...others } = props;
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      message={<span id="client-snackbar">{message}</span>}
      action={[
        <IconButton key="0" onClick={() => props.onClose()} color="primary">
          <CloseIcon />
        </IconButton>
      ]}
      {...others}
    />
  );
}

export default CustomSnackbarContentWrapper;
