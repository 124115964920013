import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import firebase from "firebase/app";
import "firebase/auth";
import {
  AppBar,
  Toolbar,
  Drawer,
  ListSubheader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

import pages from "../pagesList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBars,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

function Header(props) {
  const [drawer, setDrawer] = useState(false);
  const accessor = useSelector(state => state.app.accessor);
  const [sidebarItems, setSidebarItems] = useState([]);
  const groups = accessor.groups;

  useEffect(() => {
    setSidebarItems(
      pages
        .filter(page => page.sidebar === true) // Filter only pages which should be shown in the sidebar
        .map(
          (page, index) =>
            groups &&
            page.groups.find(
              itemGroup =>
                groups.reduce(
                  (hasGroup, group) => hasGroup || itemGroup === group,
                  false
                ) // If user has a role which has access to this sidebar element
            ) && (
              <ListItem
                button
                component={Link}
                id={page.path}
                key={index}
                to={`/${page.path}`}
                disabled={props.page === index}>
                {page.icon}
                <ListItemText inset>{page.name}</ListItemText>
              </ListItem>
            )
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, menu } = props;

  return (
    <Grid container>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <FontAwesomeIcon
            icon={menu === "back" ? faChevronLeft : faBars}
            onClick={() =>
              menu === "back" ? props.history.goBack() : setDrawer(true)
            }
            size="lg"
            style={{ margin: "0px 10px" }}
          />
          <Typography variant="h3">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <List component="nav">
          <ListSubheader component="div">{accessor.name}</ListSubheader>
          <ListItem button component={Link} to="/">
            <FontAwesomeIcon icon={faHome} size="lg" />
            <ListItemText inset primary="Home" />
          </ListItem>
          <Divider />
          {sidebarItems}
          <Divider />
          <ListItem button onClick={() => firebase.auth().signOut()}>
            <ExitToAppIcon />
            <ListItemText inset primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
}

export default withRouter(Header);
