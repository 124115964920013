import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateOrderId,
  openErrorToast,
  fetchTallies
} from "../../redux/actions";
// import { reverseGeocode } from "../../Api";

import "./index.css";

import Header from "../../components/Header";

import { TextField, Grid, Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import AddIcon from "@material-ui/icons/Add";

import CreateNewTallyDialog from "./CreateNewTallyDialog";
import { displayTime, distance } from "../../common";

const useStyles = makeStyles(theme => ({
  fullWidthMax300: {
    width: "100%",
    maxWidth: "300px",
    margin: "8px 0px"
  },
  tallyCard: {
    width: 300,
    margin: 16,
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  tallyNumber: {
    marginTop: "10px",
    marginBottom: "23px"
  },
  createdTime: {
    fontStyle: "italic",
    color: "#A5a5a5"
  },
  tallyContainer: {
    marginTop: 23,
    width: "100%"
  }
}));

export default function OrderTallyPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const orderId = useSelector(state => state.app.orderId);
  const tallies = useSelector(state => state.tally.orderTallies);
  const loading = useSelector(state => state.tally.loading);
  const online = useSelector(state => state.app.online);

  const [dialog, setDialog] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [draggedTally, setDraggedTally] = useState({ index: -1, tally: {} });
  const [targetTally, setTargetTally] = useState({ index: -1, tally: {} });

  // Run on Network connectivity changes
  useEffect(() => {
    if (orderId) handleSubmitOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  const handleKeyPress = e => {
    if (e.which === 13) handleSubmitOrderId();
  };

  const handleSubmitOrderId = async () => {
    if (orderId.length === 0) dispatch(openErrorToast("Order Id missing"));
    else {
      dispatch(fetchTallies(orderId));
      setShowResult(true);
    }
  };

  const handleOpenTally = tallyId => props.history.push(`/tally/${tallyId}`);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="main-container">
      <Header title="Tally" />

      <TextField
        id="orderId"
        label="Order Id"
        disabled={!online}
        type="number"
        className={classes.fullWidthMax300}
        value={orderId}
        onChange={e => {
          dispatch(updateOrderId(e.target.value));
          setShowResult(false);
        }}
        onKeyPress={handleKeyPress}
      />
      <Button
        id="getOrderData"
        color="primary"
        variant="outlined"
        className={classes.fullWidthMax300}
        onClick={() => handleSubmitOrderId()}
        disabled={!online}>
        Get tallies
      </Button>
      {showResult && !loading && tallies && tallies.length > 0 ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.tallyContainer}>
          {tallies.map((tally, i) => (
            <Paper
              key={i}
              elevation={2}
              onDragOver={e => e.preventDefault()}
              onDragEnter={() => {
                setTargetTally({ index: i, tally });
              }}
              onDragExit={() => {
                setTargetTally({ index: -1, tally: {} });
              }}
              onDrop={e => {
                if (draggedTally.index === targetTally.index) return;

                const canMerge = [
                  draggedTally.tally.stage === targetTally.tally.stage &&
                    draggedTally.tally.site === targetTally.tally.site, // Same type of tally
                  distance(
                    draggedTally.tally.location,
                    targetTally.tally.location
                  ) < 0.1
                  // Distance between source and destination tallies are less than factor of 0.1 (Lat,Lng)
                  // Todo - 0.1 is very generous (16km) This is just to show how this works
                ];
                console.log(canMerge);
              }}
              className={classes.tallyCard}
              onClick={() => {
                handleOpenTally(tally.id);
              }}>
              <Typography
                variant="h3"
                draggable
                onDragEnd={() => {
                  setDraggedTally({ index: -1, tally: {} });
                }}
                onDragStart={e => {
                  setDraggedTally({ index: i, tally });
                  e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
                }}>
                {tally.stage} @ {tally.site}
              </Typography>

              <Typography
                variant="h2"
                className={classes.tallyNumber}
                style={{
                  ...(tally.committedPackages !== tally.totalPackages
                    ? { color: "#FF5252" }
                    : {})
                }}>
                {tally.committedPackages} / {tally.totalPackages}
              </Typography>
              <Typography variant="caption" className={classes.createdTime}>
                Created: {displayTime(tally.createdAt)} at {tally.location.x},
                {tally.location.y}
              </Typography>
            </Paper>
          ))}
        </Grid>
      ) : (
        <p>No tallies found for this order</p>
      )}
      <Button
        color="primary"
        variant="contained"
        className={classes.fullWidthMax300}
        style={{ marginTop: "30px" }}
        onClick={() => setDialog(true)}>
        <AddIcon />
        Add New Tally
      </Button>

      <CreateNewTallyDialog
        open={dialog}
        onClose={tallyId => {
          if (tallyId && typeof tallyId === "number") handleOpenTally(tallyId);
          else setDialog(false);
        }}
        orderid={orderId}
      />
    </Grid>
  );
}
