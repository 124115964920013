import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openSuccessToast,
  playAlarm,
  fetchTally,
  addTallyPackage,
  openErrorToast
} from "../../redux/actions";
import "./index.css";

import * as moment from "moment";

import Header from "../../components/Header";
import RowGrid from "../../components/RowGrid";
import ConfirmPrompt from "../../components/ConfirmPrompt";
import QrReader from "react-qr-reader";
import {
  Grid,
  TextField,
  CircularProgress,
  Tabs,
  Tab,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import LocationOffIcon from "@material-ui/icons/LocationOff";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  reverseGeocode,
  tallyPackageData,
  deletePackageFromTally,
  deletePackage,
  deleteTally
} from "../../Api";

const useStyles = makeStyles(theme => ({
  halfWidth: {
    width: "50%"
  },
  alignEnd: {
    alignContent: "flex-end"
  },
  package: {
    width: "30px",
    margin: "5px",
    padding: "4px",
    border: "1px solid black",
    borderRadius: "3px"
  }
}));

export default function TallyPage(props) {
  const classes = useStyles();
  const [confirmPrompt, setConfirmPrompt] = useState({
    open: false,
    message: "",
    onConfirm: () => {}
  });
  const dispatch = useDispatch();
  const tallyId = props.match.params.id;
  const [tallyLocation, setTallyLocation] = useState("");
  const { accessor, location } = useSelector(state => state.app);

  const { orderPackages, tally, tallyPackages } = useSelector(
    state => state.tally
  );

  const thisTallyPackages = orderPackages.map(orderPackage => ({
    ...orderPackage,
    tallyPackage: tallyPackages.find(
      scannedPackage => scannedPackage.packageId === orderPackage.id
    )
  }));

  const [packageLabel, setPackageLabel] = useState("");
  const [tallyPackageDialog, setTallyPackageDialog] = useState({
    open: false,
    tallyPackage: {},
    orderPackage: {}
  });

  const [scanMode, setScanMode] = useState("text");
  const [editStart, setEditStart] = useState(null);

  const fetchTallyData = () => {
    const errorCallBack = () => props.history.push("/orderTally");
    dispatch(fetchTally(tallyId), errorCallBack);
  };
  // reverse geocode and print location name
  const fetchLocationData = async () => {
    if (tally && tally.location && tally.location.x && tally.location.y) {
      const { display_name } = await reverseGeocode({
        lat: tally.location.x,
        lon: tally.location.y
      });
      const words = display_name.split(",");
      setTallyLocation(`${words[0]},${words[1] || ""}`);
    }
  };

  useMemo(() => {
    fetchLocationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tally.location]);

  useEffect(() => {
    fetchTallyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tallyId]);

  const handleKeyPress = e => {
    if (e.which === 13) logPackage();
  };

  const logPackage = async scannedPackageLabel => {
    const fromScanStart = moment().diff(editStart, "millisecond");
    setEditStart(null);
    const [orderId, packageSequence] = scannedPackageLabel
      ? scannedPackageLabel.split("-")
      : packageLabel.split("-");

    if (+orderId === tally.orderId && packageSequence) {
      const data = {
        orderId: +orderId,
        packageSequenceNumber: +packageSequence,
        customJson: JSON.stringify({
          scanMode:
            scanMode === "text"
              ? fromScanStart > 900
                ? "manual"
                : "auto"
              : "camera"
        }),
        tallyId: +tallyId,
        location
      };
      dispatch(addTallyPackage(data));
    } else {
      dispatch(playAlarm());
      dispatch(
        openErrorToast(
          +orderId !== tally.orderId
            ? "Order Id mismatch. Are you sure this package belongs to this tally?"
            : "Sequence number is missing"
        )
      );
    }
    setPackageLabel("");
  };

  const handleScan = scanValue => scanValue && logPackage(scanValue);

  const handleDeleteTally = async () => {
    setConfirmPrompt({
      open: true,
      message: "Deleting a tally is not reversible",
      onConfirm: async () => {
        await deleteTally(tallyId);
        props.history.push(`/orderTally`);
      }
    });
  };

  const handleError = err => dispatch(openErrorToast(JSON.stringify(err)));

  const scannedPackages =
    tally && tallyPackages
      ? [...new Set(tallyPackages.map(p => p.packageSequenceNumber))].length
      : 0;

  const isOpsManagerOrAdmin = [
    "Developer Administrators",
    "Application Administrators",
    "Operations Managers"
  ].some(approvedGroup => accessor.groups.includes(approvedGroup));

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Header title={`Tally - ${props.match.params.id}`} menu="back" />
      {tally ? (
        <Grid
          container
          className="dashboardBody"
          direction="column"
          style={{
            margin: "70px 10px",
            width: "90%",
            maxWidth: "400px"
          }}
          justify="flex-start"
          alignItems="center">
          <RowGrid
            justify="space-between"
            alignItems="flex-end"
            style={{ width: "100%", margin: "5px 5px 5px 0px" }}>
            <span onClick={() => props.history.push("/orderTally")}>
              <Typography variant="caption">Order Id</Typography>
              <Typography variant="body1">{tally.orderId}</Typography>
            </span>
            <span>
              <Typography variant="caption">created by</Typography>
              <Typography variant="body1">{tally.createdBy}</Typography>
            </span>
            {location.lat === 0 && location.lon === 0 ? (
              <LocationOffIcon />
            ) : (
              <LocationOnIcon
                onClick={async () => {
                  let currentLocation = await reverseGeocode(location);
                  dispatch(
                    openSuccessToast(
                      `You are at ${currentLocation.display_name}.`
                    )
                  );
                }}
              />
            )}
          </RowGrid>
          <RowGrid
            justify="space-between"
            alignItems="flex-start"
            style={{
              width: "100%",
              marginTop: "20px",
              color: "#838383"
            }}>
            <Grid item className={classes.halfWidth}>
              <Typography variant="caption">Location</Typography>
              <Typography variant="body2">
                {tallyLocation ? tallyLocation : `Location not available`}
              </Typography>
            </Grid>
            <Grid item className={classes.halfWidth}>
              <Typography variant="caption">Stage & Site</Typography>
              <Typography variant="body2">
                {tally.stage} @ {tally.site}
              </Typography>
            </Grid>

            {scannedPackages === 0 && (
              <Grid item>
                <IconButton onClick={handleDeleteTally}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </RowGrid>

          <RowGrid
            justify="space-around"
            style={{ margin: "20px 0px", width: "90%", maxWidth: "400px" }}
            alignItems="flex-start">
            <Tabs
              value={scanMode}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) => setScanMode(newValue)}
              variant="standard">
              <Tab label="text" value="text" id="text" />
              <Tab label="camera" value="camera" id="camera" />
            </Tabs>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-end">
              <Typography variant="caption" className={classes.alignEnd}>
                Tally
              </Typography>
              <Typography variant="h3" className={classes.alignEnd}>
                {orderPackages.length === 0
                  ? "No packages"
                  : `${scannedPackages} / ${orderPackages.length}`}
              </Typography>
            </Grid>
          </RowGrid>

          {scanMode === "text" ? (
            <TextField
              id="package"
              style={{
                maxWidth: "400px",
                width: "100%"
              }}
              autoFocus
              variant="outlined"
              autoComplete="off"
              label="Scan with focus here"
              value={packageLabel}
              onChange={e => {
                if (e.target.value === "") setEditStart(null);
                else if (!editStart) setEditStart(moment());
                setPackageLabel(e.target.value);
              }}
              onKeyPress={handleKeyPress}
            />
          ) : (
            <QrReader
              delay={2000}
              facingMode="environment"
              style={{
                width: "90%",
                maxWidth: "400px"
              }}
              onError={handleError}
              onScan={handleScan}
            />
          )}

          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            style={{
              margin: "40px 0px",
              maxWidth: "400px",
              width: "100%"
            }}>
            {thisTallyPackages &&
              thisTallyPackages.map(orderPackage => (
                <Grid
                  key={orderPackage.id}
                  container
                  className={classes.package}
                  direction="row"
                  justify="center"
                  style={{
                    backgroundColor: tallyPackages.find(
                      scannedPackage =>
                        scannedPackage.packageSequenceNumber ===
                        orderPackage.packageSequenceNumber
                    )
                      ? isOpsManagerOrAdmin &&
                        tallyPackages.find(
                          scannedPackage =>
                            scannedPackage.packageSequenceNumber ===
                            orderPackage.packageSequenceNumber
                        ).packageScanMode === "manual"
                        ? "#D0EFC0"
                        : "#41b800"
                      : "#FFFFFF"
                  }}
                  onClick={async () => {
                    if (
                      !orderPackage.tallyPackage &&
                      tally.stage === "loading" &&
                      tally.site === "source"
                    )
                      setTallyPackageDialog({
                        open: true,
                        orderPackage,
                        tallyPackage: {} // packed,
                      });
                    else if (
                      isOpsManagerOrAdmin &&
                      // Packed,  then use tallyPackage
                      tallyPackages.find(
                        scannedPackage =>
                          scannedPackage.packageSequenceNumber ===
                          orderPackage.packageSequenceNumber
                      )
                    ) {
                      const { tallyPackage } = await tallyPackageData(
                        tallyId,
                        orderPackage.id
                      );
                      if (tallyPackage)
                        setTallyPackageDialog({
                          orderPackage: {},
                          open: true,
                          tallyPackage
                        });
                    }
                  }}>
                  {orderPackage.packageSequenceNumber}
                </Grid>
              ))}
          </Grid>
        </Grid>
      ) : (
        <CircularProgress className="loading-spinner" color="primary" />
      )}
      <TallyPackageDialog
        open={tallyPackageDialog.open}
        tallyPackage={tallyPackageDialog.tallyPackage}
        orderPackage={tallyPackageDialog.orderPackage}
        onClose={() => {
          fetchTallyData();
          setTallyPackageDialog({
            open: false,
            tallyPackage: {},
            orderPackage: {}
          });
        }}
      />
      <ConfirmPrompt
        onConfirm={confirmPrompt.onConfirm}
        open={confirmPrompt.open}
        message={confirmPrompt.message}
        onClose={() =>
          setConfirmPrompt({ open: false, message: "", onConfirm: () => {} })
        }
      />
    </Grid>
  );
  function TallyPackageDialog(props) {
    const { tallyPackage, orderPackage } = props;

    const handleDeletePackage = async () => {
      if (tallyPackage && tallyPackage.tallyId) {
        setConfirmPrompt({
          open: true,
          message:
            "This will delete the package from the tally and cannot be reversed.",
          onConfirm: async () => {
            const { tallyId, packageId } = tallyPackage;
            deletePackageFromTally({ tallyId, packageId });
            props.onClose();
          }
        });
      } else if (orderPackage && orderPackage.id) {
        setConfirmPrompt({
          open: true,
          message:
            "This will delete the package permanently and cannot be reversed.",
          onConfirm: async () => {
            const { message } = await deletePackage(orderPackage.id);
            message && dispatch(openErrorToast(message));
            props.onClose();
          }
        });
      }
    };

    return (
      <Dialog open={props.open} onClose={() => props.onClose()}>
        <DialogTitle>Package details</DialogTitle>
        <DialogContent>
          {tallyPackage && tallyPackage.location && tallyPackage.customJson && (
            <Grid container direction="column">
              <Grid item>
                Scanned at: {tallyPackage.location.x},{tallyPackage.location.y}
              </Grid>
              <Grid item>Scan mode: {tallyPackage.customJson.scanMode}</Grid>
            </Grid>
          )}
          {orderPackage && (
            <Grid container direction="column">
              <Grid item>
                Package: {orderPackage.orderId}-
                {orderPackage.packageSequenceNumber}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeletePackage} color="primary">
            {tallyPackage && tallyPackage.tallyId
              ? `Remove from tally`
              : `Delete package`}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
