import { getToken, baseUrl } from "./index";

export const getTalliesFromOrderId = async orderId =>
  fetch(`${baseUrl}/tallies?order_id=${orderId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const createNewTally = async data =>
  fetch(`${baseUrl}/tallies`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(response => response.json());

export const getTallyData = async tallyId =>
  fetch(`${baseUrl}/tallies/${tallyId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

export const addPackageToTally = async ({
  orderId,
  packageSequenceNumber,
  customJson,
  tallyId,
  location
}) => {
  const body = {
    orderId,
    packageSequenceNumber,
    customJson,
    longitude: location.lon,
    latitude: location.lat
  };

  return fetch(`${baseUrl}/tallies/${tallyId}/packages`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

/**
 *
 * @param {number} tallyId
 */

export const deleteTally = async tallyId =>
  fetch(`${baseUrl}/tallies/${tallyId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 *
 *
 */
export const deletePackageFromTally = async ({ tallyId, packageId }) =>
  fetch(`${baseUrl}/tallies/${tallyId}/packages/${packageId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 *
 * @param {number} tallyId
 * @param {number} packageId
 */
export const tallyPackageData = async (tallyId, packageId) =>
  fetch(`${baseUrl}/tallies/${tallyId}/packages/${packageId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
