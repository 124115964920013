import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  addAssetsToPackage,
  getAccount,
  verifyAccount,
  setLatestAccessTime
} from "./Api";
import db from "./offlineDb/client";
import pages from "./components/pagesList";

import firebase from "firebase/app";
import "firebase/auth";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { parse } from "querystring";
import "react-dates/initialize";

import {
  Grid,
  CircularProgress,
  CssBaseline,
  Typography
} from "@material-ui/core";

import {
  openErrorToast,
  stopPlaying,
  updateLocation,
  updateOnline,
  updateOrderId,
  updateAccessor
} from "./redux/actions";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./App.css";

import Sound from "react-sound";
import alarm from "./assets/alarm.mp3";
import success from "./assets/laser.mp3";
import warning from "./assets/warning.mp3";

import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import PackingPage from "./pages/PackingPage";
import AdminPage from "./pages/AdminPage";
import OrderViewPage from "./pages/OrderViewPage";
import OrderTallyPage from "./pages/OrderTallyPage";
import TallyPage from "./pages/TallyPage";
import UploadAssetsPage from "./pages/UploadAssetsPage";
import SortPage from "./pages/SortPage";
import SettingsPage from "./pages/SettingsPage";
import ActivityPage from "./pages/ActivityDashboard";

import Toast from "./components/Toast";

import OfflineDb from "./offlineDb";

import { updateSyncingFlag } from "./redux/actions";

function App(props) {
  const isDark = useSelector(state => state.app.isDark);
  const theme = createMuiTheme({
    typography: {
      useNextVariants: true,
      h1: {
        fontSize: "59px"
      },
      h2: {
        fontSize: "37px"
      },
      h3: {
        fontSize: "23px"
      },
      body1: {
        fontSize: "14px"
      },
      body2: {
        fontSize: "16px"
      },
      caption: {
        fontSize: "10px"
      }
    },
    status: {
      error: "#FF5252"
    },
    palette: {
      type: isDark ? "dark" : "light",
      primary: {
        main: "#18CDCA"
      },
      secondary: {
        main: "#FF9D00"
      },
      error: {
        main: "#FF5252"
      },
      success: {
        main: "#41b800"
      },
      info: {
        main: "#a3a3a3"
      },
      warning: {
        main: "#FF9D00"
      }
    }
  });

  const dispatch = useDispatch();
  const sounds = useSelector(state => state.app.sounds);
  const online = useSelector(state => state.app.online);
  const orderId = useSelector(state => state.app.orderId);
  const [loading, setLoading] = useState(true);
  const syncing = useSelector(state => state.app.syncing);

  const updateNetworkStatus = async () => {
    dispatch(updateOnline(window.navigator.onLine));
  };

  const syncOfflineData = async () => {
    if (online) {
      dispatch(updateSyncingFlag(true));

      const uncommittedPackages = await db.table("packages").toArray();
      uncommittedPackages.forEach(async uncommitedPackage => {
        await addAssetsToPackage(uncommitedPackage);
        db.table("packages").delete(uncommitedPackage.id);
      });
    } else {
      dispatch(updateSyncingFlag(false));
    }
  };

  const listenToLocationChanges = () => {
    navigator.geolocation.watchPosition(async position => {
      dispatch(
        updateLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude
        })
      );
    });
  };

  const listenToNetworkChanges = () => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);
  };

  const initializeOrderId = async () => {
    if (!orderId) {
      const syncedOrderId = await OfflineDb.getSyncedOrderId();
      if (syncedOrderId) dispatch(updateOrderId(syncedOrderId));
    }
  };
  const listenToAuthStateChanges = async () => {
    firebase.auth().onAuthStateChanged(async user => {
      setLoading(false);
      // to handle Redirect and URL Parameters

      let { redirect, ...propObject } = parse(props.location.search.slice(1));
      if (propObject.error) dispatch(openErrorToast(propObject.error));

      if (user) {
        const { accounts } = await getAccount(user.email);

        if (accounts[0]) {
          const account = accounts[0];
          if (!account.verifiedFlag)
            verifyAccount(account.id).catch(() =>
              dispatch(openErrorToast("Not able to verify account"))
            ); // Verify if unverified
          if (account) dispatch(updateAccessor(account)); // update redux
          if (account.id) setLatestAccessTime(account.id);
        } else {
          dispatch(openErrorToast("Access Denied. Account not created yet."));
          props.history.push("/");
        }
      }

      if (user && window.location.pathname === "/login")
        props.history.push(
          `/${redirect || ""}${
            Object.entries(propObject).length > 0
              ? `?${Object.entries(propObject)
                  .map(([key, value]) => `${key}=${value}`)
                  .join("&")}`
              : ``
          }`
        );
      // if in login page redirect the user to the redirect OR home page
      else if (!user && window.location.pathname !== "/login")
        props.history.push("/login");
      // if in any other page redirect the user to the login page
    });
  };

  useEffect(() => {
    syncOfflineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  useEffect(() => {
    listenToLocationChanges();
    listenToNetworkChanges();
    listenToAuthStateChanges();
    initializeOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          {loading ? (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              <CircularProgress color="primary" className="loading-spinner" />
            </Grid>
          ) : (
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <ProtectedRoute
                exact
                path="/orderTally"
                component={OrderTallyPage}
              />
              <ProtectedRoute exact path="/admin" component={AdminPage} />
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/tally/:id" component={TallyPage} />
              <ProtectedRoute exact path="/activity" component={ActivityPage} />
              <ProtectedRoute
                exact
                path="/orderView"
                component={OrderViewPage}
              />
              <ProtectedRoute
                exact
                path="/uploadAssets"
                component={UploadAssetsPage}
              />
              <ProtectedRoute exact path="/pack" component={PackingPage} />
              <ProtectedRoute exact path="/sort" component={SortPage} />
              <ProtectedRoute exact path="/settings" component={SettingsPage} />
              <ProtectedRoute exact path="/" component={HomePage} />

              <Route path="*" component={ErrorPage} />
            </Switch>
          )}
          <Toast />
          {//Banner to show offline status
          !online && (
            <Grid
              container
              className="online-banner"
              justify="center"
              alignItems="center">
              <Typography variant="body1">
                {online || "You are not connected to internet."}
                {syncing && "Syncing."}
              </Typography>
            </Grid>
          )}
        </CssBaseline>
      </ThemeProvider>

      <Sound
        url={success}
        playStatus={sounds.success}
        onFinishedPlaying={() => dispatch(stopPlaying())}
      />

      <Sound
        url={alarm}
        playStatus={sounds.alarm}
        onFinishedPlaying={() => dispatch(stopPlaying())}
      />

      <Sound
        url={warning}
        playStatus={sounds.warning}
        onFinishedPlaying={() => dispatch(stopPlaying())}
      />
    </div>
  );
}

const ProtectedRoute = withRouter(({ path, component, ...props }) => {
  const dispatch = useDispatch();
  let propObject = parse(props.location.search.slice(1));
  const accessor = useSelector(state => state.app.accessor);
  const userGroups = accessor && accessor.groups;

  // Wait for groups
  if (!userGroups) return <CircularProgress />;
  // Authenticated user -> go to route, while waiting for group
  else if (accessor && (userHasAccess(path, userGroups) || path === "/"))
    return <Route exact path={path} component={component} {...props} />;
  // Unauthenticated user -> go to login path, but redirect to requested page once logged in
  else if (accessor) {
    dispatch(openErrorToast("You don't have access to this page"));
    return <Redirect to="/" />;
  } else {
    return (
      <Redirect
        to={`/login?redirect=${path.slice(1, 100)}${Object.entries(propObject)
          .map(([key, value]) => `&${key}=${value}`)
          .join("")}`}
      />
    );
  }
});

// Check if user groups has access to roles defined
const userHasAccess = (path, userGroups) =>
  pages
    .find(page => page.path === path.split("/")[1])
    .groups.some(group => userGroups.some(userGroup => userGroup === group));

export default withRouter(App);
