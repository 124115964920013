import {
  UPDATE_ORDER_ID,
  UPDATE_DATES,
  UPDATE_TOAST,
  UPDATE_LOCATION,
  UPDATE_SOUNDS,
  UPDATE_ONLINE,
  UPDATE_SYNC_FLAG,
  UPDATE_DARK_MODE,
  UPDATE_ACCESSOR
} from "../../constants";
import * as moment from "moment";

const initialState = {
  orderId: "",
  dates: {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day")
  },
  accessor: null,
  toast: { variant: "success", open: false, message: "" },
  online: window.navigator.onLine,
  location: { lat: 0, lon: 0 },
  syncing: false,
  sounds: { success: "STOPPED", alarm: "STOPPED", warning: "STOPPED" },
  isDark: window.localStorage.getItem("useDark") === "true"
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDER_ID:
      const { orderId } = action.payload;
      return { ...state, orderId };
    case UPDATE_DATES:
      const { dates } = action.payload;
      return { ...state, dates };
    case UPDATE_TOAST:
      const { toast } = action.payload;
      return { ...state, toast };
    case UPDATE_LOCATION:
      const { location } = action.payload;
      return { ...state, location };
    case UPDATE_SOUNDS:
      const { sounds } = action.payload;
      return { ...state, sounds };
    case UPDATE_ONLINE:
      const { online } = action.payload;
      return { ...state, online };
    case UPDATE_SYNC_FLAG:
      const { syncing } = action.payload;
      return { ...state, syncing };
    case UPDATE_DARK_MODE:
      return { ...state, isDark: action.payload.isDark };
    case UPDATE_ACCESSOR:
      return {
        ...state,
        accessor: action.payload.accessor
      };
    default:
      return state;
  }
}
