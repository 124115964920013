import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import CountUp from "react-countup";
const useStyles = makeStyles(theme => ({
  statPaper: {
    width: "150px",
    padding: "10px",
    textAlign: "center"
  }
}));

export default function Scorecard(props) {
  const classes = useStyles();
  const { caption, value } = props;

  return (
    <Grid item>
      <Paper className={classes.statPaper} onClick={() => props.onClick()}>
        <Typography variant="caption">{caption}</Typography>
        <Typography variant="h3">
          <CountUp start={0} end={value} delay={0} duration={0.75}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </Typography>
      </Paper>
    </Grid>
  );
}
