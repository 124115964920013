import { auth } from "firebase/app";
import fetch from "isomorphic-fetch";
import { APP_URL } from "../config/constants";

export * from "./accounts";
export * from "./assets";
export * from "./tallies";
export * from "./packages";

export const baseUrl = `${APP_URL}/api`;
export const getToken = async () => await auth().currentUser.getIdToken();

export const reverseGeocode = async ({ lat, lon }) =>
  fetch(
    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
  ).then(response => response.json());

export const uploadAssetList = async data => {
  let body = new FormData();
  body.append("assetList", data.assetList, data.assetList.name);
  body.append("orderId", data.orderId);
  return fetch(`${baseUrl}/assets/upload`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`
    },
    body
  }).then(response => response.json());
};

export const getDataForMainDashboard = async (fromDate, toDate) =>
  fetch(
    `${baseUrl}/dashboards/packing?created_at[]=${fromDate}&created_at[]=${toDate}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

export const getDataForLoadingDashboard = async (fromDate, toDate) =>
  fetch(`${baseUrl}/tallies?created_at[]=${fromDate}&created_at[]=${toDate}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
