import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import {
  syncOfflinePackages,
  syncOfflineTallyPackages,
  authBeforeAllActions
} from "../middlewares";
import rootSaga from "../sagas";
import appReducer from "./reducers/app";
import tallyReducer from "./reducers/tally";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({ app: appReducer, tally: tallyReducer });

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      syncOfflineTallyPackages,
      syncOfflinePackages,
      authBeforeAllActions(),
      sagaMiddleware
    )
  )
);

sagaMiddleware.run(function*() {
  yield rootSaga();
});

export default store;
