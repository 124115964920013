import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateOrderId,
  openErrorToast,
  playWarning,
  openWarningToast
} from "../../redux/actions";
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography
} from "@material-ui/core";

import Header from "../../components/Header";
import RowGrid from "../../components/RowGrid";
import { getAssetFromScanCode } from "../../Api";

export default function SortPage(props) {
  const dispatch = useDispatch();

  const orderId = useSelector(state => state.app.orderId);
  const [assetCode, setAssetCode] = useState("");
  const [displayAssetCode, setDisplayAssetCode] = useState("");
  const [dialog, setDialog] = useState({ open: false });
  const [assetGroupCode, setAssetGroupCode] = useState("");
  const [busy, setBusy] = useState(false);

  const firstFewChars = assetGroupCode
    ? assetGroupCode.substring(0, assetGroupCode.length - 5)
    : "";
  const last3Chars = assetGroupCode
    ? assetGroupCode.substring(assetGroupCode.length - 5)
    : "";

  const updateModuleCode = async () => {
    setBusy(true);
    const { assets } = await getAssetFromScanCode(assetCode, orderId);
    setBusy(false);

    if (assets.length > 0) {
      const { groupCode, assetOrderId } = assets[0];
      if (+assetOrderId !== +orderId) {
        dispatch(playWarning());
        dispatch(openWarningToast("This asset is from a different order Id."));
      }
      setAssetGroupCode(groupCode);
      setDisplayAssetCode(assetCode);
    } else dispatch(openErrorToast("Asset is not yet uploaded on tallyo"));

    setBusy(false);
    setAssetCode("");
  };
  const handleKeyPress = e => {
    if (e.which === 13 && e.target.id === "assetCode" && assetCode !== "") {
      // Fetch module code
      updateModuleCode();
    }
  };

  return (
    <Grid>
      <Header title="Sort" />
      <Grid
        container
        direction="column"
        style={{ margin: "90px 20px 50px 10px", width: "95%" }}
        justify="center"
        alignItems="center">
        <RowGrid
          justify="space-between"
          alignItems="flex-start"
          style={{ margin: "30px 10px", width: "95%" }}>
          <Grid
            container
            direction="column"
            onClick={() => setDialog({ open: true })}
            style={{ width: "fit-content", margin: "5px" }}>
            <Typography variant="caption">OrderId</Typography>
            <Typography variant="body1">{orderId || "No Order Id"}</Typography>
          </Grid>
          {busy ? (
            <CircularProgress />
          ) : (
            <Grid className="dash-bordered">
              <Typography variant="body1">{firstFewChars}</Typography>
              <Typography variant="h1">{last3Chars}</Typography>
            </Grid>
          )}
        </RowGrid>
        <TextField
          label="Scan code"
          value={assetCode}
          autoFocus
          id="assetCode"
          onChange={e => {
            setAssetCode(e.target.value);
            setAssetGroupCode("");
          }}
          onKeyPress={handleKeyPress}
        />
        <Typography>{displayAssetCode}</Typography>
      </Grid>
      <Dialog open={dialog.open} onClose={() => setDialog({ open: false })}>
        <DialogTitle>Set Order Id</DialogTitle>
        <DialogContent>
          <TextField
            id="orderId"
            className="fullWidthMax400"
            label="Order Id"
            type="number"
            value={orderId}
            onChange={e => {
              dispatch(updateOrderId(e.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(updateOrderId(orderId));
              setDialog({ open: false });
            }}
            color="primary">
            SET
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
