import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  confirmDialog: {
    height: "auto"
  }
}));

function ConfirmPrompt(props) {
  const classes = useStyles();
  const { onConfirm, onCancel, message, open } = props;
  return (
    <Dialog
      open={open}
      onClose={() => props.onClose("cancel")}
      className={classes.confirmDialog}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onCancel) onCancel();
            props.onClose("cancel");
          }}>
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            if (onConfirm) await onConfirm();
            props.onClose("confirm");
          }}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPrompt.propTypes = {
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default ConfirmPrompt;
