import React, { useState, useEffect } from "react";

import Header from "../../components/Header";

import {
  getAccounts,
  addGroupToAccount,
  removeGroupFromAccount,
  deactivateAccount
} from "../../Api";

import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons/";

import MUIDataTable from "mui-datatables";
import {
  CircularProgress,
  Grid,
  Select,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button
} from "@material-ui/core";

import ConfirmPrompt from "../../components/ConfirmPrompt";
import InviteUserPopup from "./InviteUserPopup";

export default function AdminPage(props) {
  const [dialog, setDialog] = useState({ open: false });
  const [inviteUserPopup, setInviteUserPopup] = useState(false);
  const [confirmPrompt, setConfirmPrompt] = useState({
    open: false,
    message: "",
    onConfirm: () => {}
  });

  const [tableState, setTableState] = useState({
    accounts: [],
    count: 0,
    pagination: {
      rowsPerPage: 10,
      page: 0
    },
    search: ""
  });
  const [loading, setLoading] = useState(false);

  const removeGroupFromUser = async (accountId, group) => {
    await removeGroupFromAccount(accountId, group);
    refreshUserTable();
  };

  const addGroupToUser = async (accountId, group) => {
    await addGroupToAccount(accountId, group);
    refreshUserTable();
  };

  const handleDeleteUser = async (rowData, rowMeta) => {
    setConfirmPrompt({
      open: true,
      message: "Deleting will take away all accesses from the user.",
      onConfirm: async () => {
        await deactivateAccount(tableState.accounts[rowMeta].id);
        refreshUserTable();
      }
    });
  };

  const handleEditUser = async rowMeta => {
    setDialog({ open: true, account: tableState.accounts[rowMeta] });
  };

  const refreshUserTable = async () => {
    setLoading(true);
    const { accounts, count } = await getAccounts({
      pageSize: tableState.pagination.rowsPerPage,
      pageNumber: tableState.pagination.page,
      search: tableState.search
    });
    setTableState({ ...tableState, accounts, count });
    setLoading(false);
  };

  console.log(dialog);
  useEffect(() => {
    refreshUserTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState.pagination, tableState.search]);

  const columns = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "groups", label: "roles" },
    { name: "id", label: "AccountId", options: { display: false } },
    { name: "activeFlag", label: "Is active", options: { display: false } }
  ];
  const dropDownGroups = [
    { group: 3, label: "Operations Manager" },
    { group: 4, label: "Supervisor" }
  ];

  const dropDowns = dropDownGroups.map(
    dropDownGroup =>
      (!dialog.account ||
        !dialog.account.groups ||
        !dialog.account.groupIds.find(
          userGroup => userGroup === dropDownGroup.group
        )) && (
        <MenuItem key={dropDownGroup.group} value={dropDownGroup.group}>
          {dropDownGroup.label}
        </MenuItem>
      )
  );

  const options = {
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: tableState.pagination.rowsPerPage,
    page: tableState.pagination.page,
    count: tableState.count,
    searchText: tableState.search,
    rowsPerPageOptions: [1, 10, 25, 100],
    isRowSelectable: () => false,
    onTableChange: (action, thisTableState) => {
      const { page, rowsPerPage } = thisTableState;
      (action === "changePage" || action === "changeRowsPerPage") &&
        setTableState({
          pagination: { page, rowsPerPage }
        });
    },

    customRowRender: (rowData, rowMeta) => (
      <tr
        key={rowData[1]}
        style={{
          color: rowData[4] === 1 ? "inherit" : "#FF0000"
        }}>
        <td className="row-data">{rowData[0]}</td>
        <td className="row-data">{rowData[1]}</td>
        <td className="row-data">
          {rowData[2].map(group => (
            <Chip key={group} label={group} />
          ))}
        </td>
        <td>
          <IconButton onClick={() => handleEditUser(rowMeta)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteUser(rowData, rowMeta)}>
            <DeleteIcon />
          </IconButton>
        </td>
      </tr>
    )
  };

  return (
    <Grid container direction="column" className="main-container">
      <Header title="Admin" />

      {loading ? (
        <CircularProgress className="loading-spinner" color="primary" />
      ) : (
        <Grid container direction="column">
          <MUIDataTable
            data={tableState.accounts}
            options={options}
            columns={columns}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => setInviteUserPopup(true)}>
            Invite User
          </Button>
        </Grid>
      )}

      <InviteUserPopup
        open={inviteUserPopup}
        onClose={() => {
          refreshUserTable();
          setInviteUserPopup(false);
        }}
      />

      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, account: {} })}>
        <DialogTitle>Change user data</DialogTitle>
        <DialogContent>
          <Grid>
            UserName:
            {dialog.account && dialog.account.name}
          </Grid>
          <Grid>Email: {dialog.account && dialog.account.email}</Grid>
          <Grid>Phone: {dialog.account && dialog.account.phoneNumber}</Grid>
          <Grid>
            Groups:
            {((dialog.account && dialog.account.groups) || []).map(
              (group, index) => (
                <Chip
                  key={group}
                  label={group}
                  onDelete={() =>
                    removeGroupFromUser(
                      dialog.account.id,
                      dialog.account.groupIds[index]
                    )
                  }
                />
              )
            )}
            {dialog.account &&
              dialog.account.activeFlag === 1 &&
              dialog.account &&
              dropDowns.length > 0 && (
                <Select
                  value=""
                  onChange={e =>
                    addGroupToUser(dialog.account.id, e.target.value)
                  }>
                  {dropDowns}
                </Select>
              )}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmPrompt
        onConfirm={confirmPrompt.onConfirm}
        open={confirmPrompt.open}
        message={confirmPrompt.message}
        onClose={() =>
          setConfirmPrompt({ open: false, message: "", onConfirm: () => {} })
        }
      />
    </Grid>
  );
}
