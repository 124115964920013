import { call, put, select } from "redux-saga/effects";

import {
  FETCH_TALLIES_ERROR,
  FETCH_TALLIES_SUCCESS,
  FETCH_TALLIES_LOADING,
  FETCH_TALLY_ERROR,
  FETCH_TALLY_SUCCESS,
  FETCH_TALLY_LOADING,
  MUTATE_ADD_TALLY_PACKAGE_LOADING,
  MUTATE_ADD_TALLY_PACKAGE_SUCCESS,
  MUTATE_CREATE_TALLY_LOADING,
  MUTATE_CREATE_TALLY_ERROR,
  MUTATE_CREATE_TALLY_SUCCESS
} from "../constants";

import {
  openErrorToast,
  openWarningToast,
  playAlarm,
  playSuccess,
  playWarning,
  updateOrderId
} from "../actions";

import {
  getTalliesFromOrderId,
  getTallyData,
  addPackageToTally,
  createNewTally
} from "../../Api";

import OfflineDb from "../../offlineDb";

function* fetchTalliesOffline(action) {
  try {
    yield put({ type: FETCH_TALLIES_LOADING });
    const tallies = yield call(
      OfflineDb.getTalliesByOrderId,
      action.data.orderId
    );

    yield put({ type: FETCH_TALLIES_SUCCESS, data: { tallies } });
  } catch (err) {
    console.log(err);
    yield put({ type: FETCH_TALLIES_ERROR });
  }
}

function* fetchTalliesOnline(action) {
  try {
    yield put({ type: FETCH_TALLIES_LOADING });
    const response = yield call(getTalliesFromOrderId, action.data.orderId);
    if (response.success) {
      const { tallies } = response;
      yield put({ type: FETCH_TALLIES_SUCCESS, data: { tallies } });
    } else {
      yield put({ type: FETCH_TALLIES_ERROR });
    }
  } catch (error) {
    yield put({ type: FETCH_TALLIES_ERROR });
  }
}

function* fetchTallies(action) {
  const online = yield select(state => state.app.online);
  if (online) yield fetchTalliesOnline(action);
  else yield fetchTalliesOffline(action);
}

function* fetchTallyOffline(action) {
  const {
    data: { tallyId },
    errorCallBack
  } = action;

  try {
    const tally = yield call(OfflineDb.getTallyById, tallyId);
    let tallyPackages = yield call(
      OfflineDb.getTallyPackagesByTallyId,
      tallyId
    );

    const orderPackages = yield call(
      OfflineDb.getOrderPackagesByOrderId,
      tally.orderId
    );

    yield put({
      type: FETCH_TALLY_SUCCESS,
      data: { orderPackages, tally, tallyPackages }
    });
  } catch (err) {
    console.log(err);
    yield put({ type: FETCH_TALLY_ERROR });
    errorCallBack();
  }
}

function* fetchTallyOnline(action) {
  const {
    data: { tallyId },
    errorCallBack
  } = action;
  try {
    yield put({ type: FETCH_TALLY_LOADING });
    const response = yield call(getTallyData, tallyId);
    if (response.success) {
      const { orderPackages, tally, tallyPackages } = response;
      yield put({
        type: FETCH_TALLY_SUCCESS,
        data: { orderPackages, tally, tallyPackages }
      });
      yield put(updateOrderId(tally.orderId));
      yield saveTallyOffline({ orderPackages, tally, tallyPackages });
    } else {
      yield put(openErrorToast("Invalid Tally ID."));
      errorCallBack();
    }
  } catch (error) {
    yield put({ type: FETCH_TALLY_ERROR });
    errorCallBack();
  }
}

function* fetchTally(action) {
  const online = yield select(state => state.app.online);
  if (online) yield fetchTallyOnline(action);
  else yield fetchTallyOffline(action);
}

function* addPackageTallyOffline(action, synced) {
  const {
    data,
    data: { tallyId, packageSequenceNumber, orderId }
  } = action;

  try {
    let errorStatus = null,
      existingTallyPackage = null;

    const orderPackage = yield OfflineDb.getOrderPackageByOrderIdAndSequenceNumber(
      orderId,
      packageSequenceNumber
    );

    if (!orderPackage) {
      const tally = yield OfflineDb.getTallyById(tallyId);

      // Check if it is a loading at source tally
      const isLoadingAtSource =
        tally.site === "source" && tally.stage === "loading";

      errorStatus = isLoadingAtSource
        ? null
        : "ERR:TALLY:NEW_PACKAGE-NOT_LOADING_AT_SOURCE";

      // Add new Order Package
      if (isLoadingAtSource) {
        const lastOrderPackage = yield OfflineDb.getLastOrderPackage();
        yield OfflineDb.addOrderPackage({
          id: lastOrderPackage.id + 1,
          orderId,
          packageSequenceNumber,
          customJson: {}
        });
      }
    } else {
      // Check if the tally package exists
      existingTallyPackage = yield call(OfflineDb.getTallyPackage, {
        tallyId,
        packageSequenceNumber,
        orderId
      });

      errorStatus = existingTallyPackage ? "ERR:TALLY:PACKAGE_EXISTS" : null;

      if (existingTallyPackage && !existingTallyPackage.synced && synced) {
        yield OfflineDb.markTallyPackageSynced(existingTallyPackage.id);
        return;
      }
    }

    if (!errorStatus) {
      // Create new tally package
      yield OfflineDb.addTallyPackage(data, synced);
      yield put({
        type: MUTATE_ADD_TALLY_PACKAGE_SUCCESS,
        data: { tallyPackage: data }
      });
      if (!synced) yield put(playSuccess());
      return;
    }

    yield handleAddPackageErrors(errorStatus);
  } catch (err) {
    console.log(err);
  }
}

function* addPackageTallyOnline(action) {
  const { data } = action;
  try {
    const syncing = yield select(state => state.app.syncing);
    yield put({ type: MUTATE_ADD_TALLY_PACKAGE_LOADING });
    const response = yield call(addPackageToTally, data);
    if (response.success) {
      const { tallyPackage } = response;

      if (!syncing) yield put(playSuccess());
      yield put({
        type: MUTATE_ADD_TALLY_PACKAGE_SUCCESS,
        data: { tallyPackage }
      });
      yield addPackageTallyOffline(action, 1);
    } else {
      yield handleAddPackageErrors(response.status);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_TALLY_ERROR });
  }
}

function* handleAddPackageErrors(errorStatus) {
  if (errorStatus === "ERR:TALLY:PACKAGE_EXISTS") {
    yield put(playWarning());
    yield put(openWarningToast("This is already present in tally."));
  }

  if (errorStatus === "ERR:TALLY:NEW_PACKAGE-NOT_LOADING_AT_SOURCE") {
    yield put(playAlarm());
    yield put(
      openErrorToast(
        "Cannot create package. Please check why this sticker was printed. "
      )
    );
  }
}

function* addPackageTally(action) {
  const online = yield select(state => state.app.online);
  if (online) yield addPackageTallyOnline(action);
  else yield addPackageTallyOffline(action, 0);
}

function* createTally(action) {
  const { successCallBack, payload } = action;
  try {
    yield put({ type: MUTATE_CREATE_TALLY_LOADING });
    const response = yield call(createNewTally, payload);
    if (response.success) {
      const { tally } = response;
      yield put({ type: MUTATE_CREATE_TALLY_SUCCESS, data: { tally } });
      successCallBack(tally.id);
    } else {
      yield put({ type: MUTATE_CREATE_TALLY_ERROR });
    }
  } catch (error) {
    yield put({ type: MUTATE_CREATE_TALLY_ERROR });
  }
}

function* saveTallyOffline({ orderPackages, tally, tallyPackages }) {
  const { orderId, id: tallyId } = tally;

  // Mark the tally packages as synced
  tallyPackages = tallyPackages.map(tp => ({
    ...tp,
    orderId: tp.packageOrderId,
    synced: 1
  }));
  try {
    yield call(OfflineDb.deletePreviousOrderTallies, orderId);
    yield call(OfflineDb.deleteOrderPackages);
    yield call(OfflineDb.deleteOtherOrderSyncedTallyPackages, orderId);
    yield call(OfflineDb.deleteSyncedTallyPackages, orderId, tallyId);
    yield call(OfflineDb.bulkPutOrderPackages, orderPackages);
    yield call(OfflineDb.putTally, tally);
    yield call(OfflineDb.bulkPutTallyPackages, tallyPackages);
  } catch (err) {
    console.log(err);
  }
}

export { fetchTallies, fetchTally, addPackageTally, createTally };
