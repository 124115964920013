import React from "react";
import { useSelector } from "react-redux";

import Header from "../../components/Header";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";

import pages from "../../components/pagesList";

import { makeStyles } from "@material-ui/styles";

export default function HomePage(props) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    homepageGridItem: {
      width: "50%"
    },
    homepageCard: {
      backgroundColor: theme.palette.primary[theme.palette.type],
      cursor: "pointer",
      width: "50%",
      maxWidth: "300px",
      border: "1px solid #eaeaea",
      padding: "10px",
      minHeight: "100px"
    },
    homepageCardBody: {
      marginTop: "14px"
    }
  }));
  const classes = useStyles();

  const accessor = useSelector(state => state.app.accessor);
  const groups = (accessor && accessor.groups) || [];
  return (
    <Grid className="main-container">
      <Header title="Home Page" />
      <Grid container direction="row" justify="flex-start">
        {pages
          .filter(
            page =>
              page.sidebar === true &&
              groups &&
              page.groups.some(pageGroup =>
                groups.some(userGroup => userGroup === pageGroup)
              )
          )
          .map((page, index) => (
            <Paper
              key={index}
              className={classes.homepageCard}
              onClick={() => props.history.push(page.path)}>
              <Typography variant="h3">{page.name}</Typography>
              <Typography variant="body1" className={classes.homepageCardBody}>
                {page.description}
              </Typography>
            </Paper>
          ))}
      </Grid>
    </Grid>
  );
}
