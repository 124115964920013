import Dexie from "dexie";

const db = new Dexie("offlineDB");
db.version(1).stores({
  packages: `++id,packageLabel, assets, location, photos`,
  tallies: `++id,tallyId, packageLabels`
});

db.version(2).stores({
  order_packages: `id,orderId,packageSequenceNumber,[orderId+packageSequenceNumber]`,
  tally_packages: `++id,tallyId,[tallyId+orderId+packageSequenceNumber],synced,[orderId+synced]`,
  order_tallies: `id,orderId`,
  tallies: null
});

db.version(3).stores({
  tally_packages: null
});

db.version(4).stores({
  tally_packages: `[tallyId+orderId+packageSequenceNumber],tallyId,synced,orderId`
});

export default db;
