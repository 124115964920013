import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getAssetsFromOrderId } from "../../Api";
import {
  updateOrderId,
  openSuccessToast,
  openErrorToast
} from "../../redux/actions";
import { groupBy } from "lodash";

import {
  Grid,
  TextField,
  Button,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
  InputAdornment,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "../../components/Header";

import AssetDialog from "./AssetDialog";
import { printStickers, arrayToCSV } from "../../common";
import "./index.css";

const useStyles = makeStyles(theme => ({
  fullWidthMax300: {
    width: "100%",
    maxWidth: "300px"
  },
  chipAsset: {
    margin: "2.5px",
    color: "#000",
    border: "1px solid #eaeaea"
  },
  chipAssetExtraMargin: {
    marginRight: "22px"
  },
  assetCircle: {
    height: "24px"
  },
  correctUnique: {
    backgroundColor: theme.palette.success.main
  },
  correctDuplicate: {
    backgroundColor: "#D0EFC0"
  },
  packedElsewhere: {
    backgroundColor: theme.palette.error.light
  },
  extraAsset: {
    backgroundColor: theme.palette.error.main
  },
  notPacked: {
    backgroundColor: "#a3a3a3"
  },
  buttonContainer: {
    marginTop: "24px"
  }
}));

export default function OrderView(props) {
  const theme = useTheme();

  const classes = useStyles();
  const orderId = useSelector(state => state.app.orderId);
  const [busy, setBusy] = useState(false);
  const [dialog, setDialog] = useState({ open: false, asset: {} });
  const [searchScanCode, setSearchScanCode] = useState("");
  const [data, setData] = useState([]);

  const [showValue, setShowValue] = useState("package");

  const filteredData = data.filter(
    row => row.scanCode.indexOf(searchScanCode) !== -1
  );

  const unordered = groupBy(
    filteredData.map((row, index) => ({
      ...row,
      isDuplicate: filteredData
        .filter((a, innerIndex) => innerIndex <= index)
        .some(
          (innerRow, innerIndex) =>
            innerRow.scanCode === row.scanCode && innerIndex !== index
        ),
      packageLabel: row.packageOrderId
        ? `${
            row.packageOrderId
          }-${row.packageSequenceNumber.toString().padStart(3, "0")}`
        : null,
      packed: Boolean(row.packageSequenceNumber),
      correct: row.assetOrderId === row.packageOrderId
    })),
    showValue === "kanban"
      ? "packed"
      : showValue === "module"
      ? "groupCode"
      : "packageLabel"
  );
  const displayData = {};
  Object.keys(unordered)
    .sort()
    .forEach(key => {
      displayData[key] = unordered[key];
    });

  const dispatch = useDispatch();

  const handleKeyPress = e => {
    if (e.which === 13 && e.target.id === "orderId") handleSubmitOrderId();
  };

  const handleSubmitOrderId = async () => {
    if (orderId.length === 0)
      dispatch(openErrorToast("Order Id cannot be blank"));
    else {
      setBusy(true);
      const { assets } = await getAssetsFromOrderId(orderId);
      setBusy(false);
      setData(assets);
    }
  };
  // Run once
  useEffect(() => {
    if (orderId) handleSubmitOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      direction="column"
      className="main-container"
      justify="center"
      alignItems="center">
      <Header title="Asset Status" />

      <TextField
        id="orderId"
        className={classes.fullWidthMax300}
        label="Order Id"
        type="number"
        value={orderId}
        onChange={e => {
          setData([]);
          dispatch(updateOrderId(e.target.value));
        }}
        onKeyPress={handleKeyPress}
      />
      <Button
        id="submit"
        className={classes.fullWidthMax300}
        color="primary"
        variant="contained"
        style={{ marginTop: "10px" }}
        onClick={() => handleSubmitOrderId()}>
        Fetch assets
      </Button>
      {busy && <CircularProgress color="primary" className="loading-spinner" />}
      {data.length > 0 && (
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start">
            <Tabs
              value={showValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) => setShowValue(newValue)}
              variant="standard">
              <Tab label="Is Packed" value="kanban" />
              <Tab label="By Module" value="module" />
              <Tab label="By Package" value="package" />
            </Tabs>
            <TextField
              id="assetSearch"
              placeholder="Search Asset by Id"
              value={searchScanCode}
              style={{ margin: "10px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className="action"
                    onClick={() => setSearchScanCode("")}>
                    <CloseIcon />
                  </InputAdornment>
                )
              }}
              onChange={e => setSearchScanCode(e.target.value)}
            />
          </Grid>

          <Grid
            container
            direction="column"
            style={{ marginTop: "20px" }}
            justify="center"
            alignItems="center">
            {Object.entries(displayData).map(([key, assets]) => (
              <Grid
                container
                key={key}
                className={classes.packingRow}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Grid className="width15pc keyContainer">
                  <b>
                    {showValue === "kanban"
                      ? key === "true"
                        ? "Packed"
                        : "Not packed"
                      : key === "null"
                      ? showValue === "module"
                        ? "Not grouped"
                        : "Not packed"
                      : key}
                  </b>
                  <SpanIfGreaterThan0
                    value={
                      assets.filter(
                        a => a.packed && a.correct && !a.isDuplicate
                      ).length
                    }
                    color="#41b800"
                  />
                  <SpanIfGreaterThan0
                    value={
                      assets.filter(a => a.packed && a.correct && a.isDuplicate)
                        .length
                    }
                    color="#D0EFC0"
                  />

                  <SpanIfGreaterThan0
                    color={theme.palette.error.main}
                    value={
                      assets.filter(
                        a =>
                          a.packed && !a.correct && +a.assetOrderId !== +orderId
                      ).length
                    }
                  />
                  <SpanIfGreaterThan0
                    color={theme.palette.error.light}
                    value={
                      assets.filter(
                        a =>
                          a.packed && !a.correct && +a.assetOrderId === +orderId
                      ).length
                    }
                  />
                  <SpanIfGreaterThan0
                    color="#a3a3a3"
                    value={
                      assets.filter(a => !a.packed).length > 0 &&
                      assets.filter(a => !a.packed).length
                    }
                  />
                </Grid>
                <Grid className="width75pc" container style={{ margin: "8px" }}>
                  {assets.map((asset, i) => (
                    <Chip
                      key={i}
                      className={`${classes.chipAsset} ${
                        showValue === "kanban" && (i + 1) % 5 === 0
                          ? classes.chipAssetExtraMargin
                          : null
                      } ${
                        asset.packed
                          ? asset.correct
                            ? !asset.isDuplicate
                              ? classes.correctUnique
                              : classes.correctDuplicate
                            : +asset.assetOrderId === +orderId
                            ? classes.packedElsewhere
                            : classes.extraAsset
                          : classes.notPacked
                      } ${showValue === "kanban" && classes.assetCircle} `}
                      onClick={() => setDialog({ open: true, asset })}
                      label={
                        <span>{showValue === "kanban" || asset.scanCode}</span>
                      }
                      style={{
                        height: showValue === "kanban" ? "24px" : "32px"
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <LegendChip
              chipClasses={classes.correctUnique}
              label="Correct and Unique"
            />
            <LegendChip
              chipClasses={classes.correctDuplicate}
              label="Correct and Duplicate"
            />
            <LegendChip
              chipClasses={classes.packedElsewhere}
              label="Packed elsewhere"
            />
            <LegendChip chipClasses={classes.extraAsset} label="Extra assets" />
            <LegendChip chipClasses={classes.notPacked} label="Not Packed" />
          </Grid>
          <Grid container justify="center" className={classes.buttonContainer}>
            {/*showValue !== "module" && (
              <Button
                variant="contained"
                color="primary"
                className={classes.sendAsset}
                onClick={() => {
                  showValue === "package"
                    ? printStickers(
                        Object.entries(displayData)
                          .filter(([key, value]) => key !== "null")
                          .map(([key, value]) => ({
                            packageLabel: key,
                            assets: value,
                            packedBy: value[0].packedBy
                          }))
                      )
                    : showValue === "kanban" &&
                      arrayToCSV(displayData.true, orderId);
                }}>
                {showValue === "package"
                  ? "Generate package labels"
                  : showValue === "kanban" && "Print list"}
              </Button>
              )*/}
          </Grid>
        </Grid>
      )}

      <AssetDialog
        open={dialog.open}
        asset={dialog.asset}
        onClose={context => {
          setDialog({ open: false, asset: {} });
          context === "cancel" || handleSubmitOrderId();
        }}
      />
    </Grid>
  );

  function LegendChip(props) {
    const { chipClasses, label } = props;
    return (
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Chip
            className={`${classes.chipAsset} ${classes.assetCircle} ${chipClasses}`}
          />
          <Typography variant="body2">{label} &emsp;&emsp;</Typography>
        </Grid>
      </Grid>
    );
  }

  function SpanIfGreaterThan0(props) {
    const { value, color } = props;
    return (
      <span style={{ color }}>{value > 0 ? <>&emsp;&emsp;{value}</> : ``}</span>
    );
  }
}
