import { UPDATE_ACCESSOR } from "../constants";

const authBeforeAllActions = () => {
  const actionsQueue = [];

  return store => next => action => {
    const {
      app: { accessor }
    } = store.getState();

    if (accessor) {
      for (let i = 0; i < actionsQueue.length; i++) {
        next(actionsQueue.shift());
      }
      return next(action);
    }

    if (!accessor && action.type !== UPDATE_ACCESSOR) {
      actionsQueue.push(action);
    }

    if (action.type === UPDATE_ACCESSOR) {
      next(action);

      if (action.payload.accessor) {
        for (let i = 0; i < actionsQueue.length; i++) {
          next(actionsQueue.shift());
        }
      }
    }

    return next(action);
  };
};

export default authBeforeAllActions;
