import React, { useState, useEffect } from "react";
import Select from "react-select";

import { getSupervisors } from "../../Api";

import { makeStyles, useTheme } from "@material-ui/styles";

export default function SupervisorSelector(props) {
  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    supervisorDropdown: {
      width: "200px"
    }
  }));
  const classes = useStyles();
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    const asyncGetSupervisors = async () => {
      const { accounts } = await getSupervisors();
      setSupervisors(accounts);
    };
    asyncGetSupervisors();
  }, []);

  const backgroundColor =
    theme.palette.type === "dark" ? theme.palette.grey.A400 : "#FFFFFF";

  const customStyles = {
    container: styles => ({ ...styles, color: "inherit" }),
    singleValue: styles => ({
      ...styles,
      color: "inherit",
      backgroundColor
    }),
    control: styles => ({
      ...styles,
      borderColor: "#dbdbdb",
      color: "inherit",
      backgroundColor
    }),
    menu: styles => ({
      ...styles,
      width: "200px",
      color: "inherit",
      backgroundColor
    }),
    menuList: styles => ({
      ...styles,
      width: "200px",
      color: "inherit",
      backgroundColor
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected
        ? theme.palette.primary.main
        : state.isFocused
        ? theme.palette.primary.light
        : backgroundColor
    })
  };

  const supervisorOptions = supervisors.map(supervisor => ({
    value: supervisor.id,
    label: supervisor.name
  }));
  return (
    <Select
      id="supervisor-selector"
      styles={customStyles}
      isMulti={true}
      placeholder="Select Supervisor"
      options={supervisorOptions}
      isClearable={true}
      className={classes.supervisorDropdown}
      onChange={e => props.onChange(e)}
    />
  );
}
